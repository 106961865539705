import {
    OPEN_MODAL,
    CLOSE_MODAL,
    OPEN_SUB_MODAL,
    OPEN_SUB_MODAL_INV,
    NEXT_MODAL
} from './../action-types';
import { Action, Dispatch } from 'redux';
import _ from 'lodash';
import React from 'react';
import {IUser} from "../../types/user";
import {IUserPrompt} from "../../types/config";

export interface IModalStyle {
  withCloseBtn?: boolean,
  pictogram?: string,
  dialogClass?: string,
  wrapperClass?: string,
  bgClass?: string,
  permanent?: boolean,
  onboarding?: boolean,
  logo?: boolean,
  pictogramWrapperClass?: string
}
export interface IModalDialog {
  modalComponent?: React.ReactNode;
  style?: IModalStyle;
  user?: Partial<IUser>;
  dialog?: IUserPrompt;
  inSequence?: boolean;
}
export interface IModalState {
  modalDialogs: IModalDialog[];
}
export interface IModalAction extends Action {
  payload?: IModalDialog;
}

const initState: IModalState = {
  modalDialogs: []
};

export const modalReducer = (state = initState, action: IModalAction): IModalState => {
  switch(action.type) {
    case OPEN_SUB_MODAL_INV:
      return {
        modalDialogs: [
          action.payload!,
          ...state.modalDialogs
        ]
      };
    case OPEN_SUB_MODAL:
      return {
        modalDialogs: [
          ...state.modalDialogs,
          action.payload!
        ]
      };
    case OPEN_MODAL:
      return {
        modalDialogs: [action.payload!]
      };
    case CLOSE_MODAL:
      const modifiedList = _.clone(state.modalDialogs);
      const modal = modifiedList.pop();
      return {
        modalDialogs: modifiedList
      };
    case NEXT_MODAL:

    default: 
      return state;
  }
};
