import { Action } from 'redux';
import { IUserPrompt, ITranslation, ICountries } from '../../types/config';
import { LIST_USER_PROMPT_ORDER, TRANSLATON_FETCHED, COUNTRIES_FETCHED } from '../action-types';

interface IConfigState {
  userPrompt: IUserPrompt[];
  translation: ITranslation[];
  countries: ICountries;
  next: string;
}

interface IConfigAction extends Action {
  payload: IConfigState;
}

const initState: IConfigState = {
  userPrompt: [],
  translation: [],
  countries: {},
  next: ''
};

export const configReducer = (state = initState, action: IConfigAction): IConfigState => {
  switch(action.type) {
    case LIST_USER_PROMPT_ORDER: 
      return { 
        ...state,
        userPrompt: action.payload.userPrompt
      };
    case TRANSLATON_FETCHED: 
      return { 
        ...state,
        translation: action.payload.translation
      };
    case COUNTRIES_FETCHED: 
      return {
        ...state,
        countries: action.payload.countries
      };
    default:
      return state;
  }
}
