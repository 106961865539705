import axios from '../../utils/http';

export class SportRepo {
  public static async listTranslated(language: string) {
    return axios.get(`/sport/?lan=${language}`)
      .then(res => res.data);
  }

  public static async trySport(language: string) {
    return axios.get(`/sport/try/next/?lan=${language}`)
      .then(res => res.data);
  }

  public static async findSportById(id: number) {
    return axios.get(`/sport/${id}/`)
      .then(res => res.data);
  }
}
