import React from 'react';
import { IUser } from '../../types/user';
import { ISport, ALREADY_PLAYED, LIKE_TO_TRY, ICustomLabels, ISportCategory } from '../../types/sport';
import { assocSportsWithUser } from '../../helpers/assocSportsWithUser';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import './friend-list.component.scss';
import { ITranslation } from '../../types/config';
import { findTranslation } from '../../utils/findTranslation';

interface IFriendListProps {
  me: IUser;
  friendList: IUser[];
  translation: ITranslation[];
  sportList: ISport[];
}

const FriendList: React.FC<IFriendListProps> = (props: IFriendListProps) => {
  const {
    friends_prompt_like_to_try,
    friends_prompt_played_already
  } = findTranslation(props.translation, [
    'friends_prompt_like_to_try',
    'friends_prompt_played_already'
  ]);

  const sportPreferencesLabels: ICustomLabels[] = [
    {
      label: friends_prompt_like_to_try,
      value: LIKE_TO_TRY
    },
    {
      label: friends_prompt_played_already,
      value: ALREADY_PLAYED
    }
  ];

  const renderSportItems = (category: ISportCategory) => {
    return category.sports.map((sport: ISport) => {
      const { icon } = sport.icons.find(icon => icon.color === category.color)!;
      return (
          <div className="sport-item" key={sport!.id}>
            <img
                className="sport-icon"
                alt="sport-icon"
                src={icon}/>
            <div className="sport-name">
              {sport!.name}
            </div>
          </div>
      )
    })
  };

  const renderFriendList = (friend: IUser) => {
    {
      const category = assocSportsWithUser(friend, props.sportList!, sportPreferencesLabels, props.me, false)
          .filter(c => c.sports.length);
      const fullname = `${friend.first_name || ''} ${friend.last_name || ''}`;

      return (
          <div className="friend-item" key={friend.username}>
            <div className="details">
              <img
                  className="avatar"
                  alt="user-avatar"
                  src={friend.picture}/>
              <div className="fullname">
                {fullname}
              </div>
            </div>

            { !!category.length &&
              <div className="sports">
                {category.map(category => (
                    <div key={category.label} className="sport-category">
                      {!!category.sports.length && (
                          <>
                            <div className="description">{category.label}</div>
                            <div className="category-content">
                              {renderSportItems(category)}
                            </div>
                          </>
                      )}
                    </div>
                ))
                }
              </div>
            }

          </div>
      )
    }
  }

  return (
      <div className="friend-list">
        {
          props.friendList
              .filter((friend: IUser) => friend.first_name && !friend.source_account)
              .map((friend: IUser) => renderFriendList(friend))
        }
      </div>
  )
};

const mapStateToProps = (store: AppState) => {
  return {
    me: store.user.me!,
    friendList: store.friends.friendList,
    sportList: store.sport.sportList,
    translation: store.config.translation
  }
};

export default connect(mapStateToProps)(FriendList);
