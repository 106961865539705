import React, { useState } from 'react';
import './carousel.component.scss';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { ITranslation, IUserPrompt } from '../../../../types/config';
import { IPicture } from '../../../../types/config';
import { closeModal } from '../../../../store/modal/modal.actions';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { findTranslation } from '../../../../utils/findTranslation';
import {IModalDialog} from '../../../../store/modal/modal.reducer';
import {IUser} from "../../../../types/user";
import {updateProfile} from "../../../../store/user/user.actions";
import logo from "../../../../assets/img/logo_blauw.png";

interface ICarouselDialogProps {
  translation: ITranslation[];
  updateProfile: (profile: Partial<IUser>) => void;
  closeModal: () => void;
  dialog?: IUserPrompt;
  modal: IModalDialog | undefined;
  userPrompt: IUserPrompt[]
}

interface IDimensions {
  height: string;
  width: string;
  carouselIndex: number;
  images: IPicture[];
}

class CarouselDialog extends React.Component<ICarouselDialogProps, IDimensions> {
  public state: IDimensions = {
    height: '',
    width: '',
    carouselIndex: 0,
    images:  this.props.userPrompt
        .find((item: IUserPrompt) => item!.prompt === this.props.dialog!.prompt)!.images,
  };

  private setCarouselIndex = async (index: number, closable: boolean = false) => {
      const close = !this.state.images.length ||
          closable && this.state.carouselIndex === this.state.images.length - 1;

      if (close) {
        await this.props.updateProfile({
          prompt: Number(this.props.dialog!.order) + 1
        });
        return;
      }

      this.setState({ carouselIndex : index });
  };

  componentDidMount () {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  render () {
    const prompt = this.props.dialog!.prompt;
    const translations = findTranslation(this.props.translation, [
      `${prompt}_button_1`,
      `${prompt}_button_2`
    ]);
    const buttons = {
      _1: translations[`${prompt}_button_1`],
      _2: translations[`${prompt}_button_2`]
    };

    const images = this.state.images
        .map((item, index) =>
            <div key={index} className='image-container'
                 style={{
                   backgroundImage: `url(${item.picture})`,
                   height: this.state.height,
                   width: this.state.width
                 }}  />
        );

    const arrows = {
      'left': (this.state.carouselIndex > 0 && this.state.images.length) ? '' : 'image-carousel__arrow--hidden',
      'right': this.state.carouselIndex < this.state.images.length - 1 ? '' : 'image-carousel__arrow--hidden'
    };

    const footer = () => {
        return (this.state.carouselIndex < this.state.images.length - 1) ||
            this.props.dialog!.prompt.includes('progress_prompt')
    }

    return (
      <div className="image-shell">
          <div className="image-carousel__header"
               style={{
                 height: this.state.height,
                 width: this.state.width
               }}>
              <div className="position-relative">
                  <div className={`image-carousel__arrow-left ${arrows.left}`}
                       onClick={() => this.setCarouselIndex(this.state.carouselIndex - 1)}
                  >&#8592;</div>
                  <img className="logo" src={logo} alt="logo"/>
              </div>
          </div>
          { images.length ?
              images[this.state.carouselIndex] :
              <div className="image-carousel__empty">Oops... There are no images to show</div> }
          { this.state.carouselIndex < this.state.images.length - 1 }
          {
              footer() &&
              <button className="image-carousel__btn"
                      onClick={() => this.setCarouselIndex(this.state.carouselIndex + 1, true)}>
                  { this.state.carouselIndex < this.state.images.length - 1 ?
                      (buttons._1 || 'next') :
                      (buttons._2 || 'close') }
              </button>
          }
      </div>
    )
  }

 private updateWindowDimensions = () => {
    const height = Math.floor(window.innerHeight / 16) * 16;
    const width = Math.floor(height * 9 / 16);

    const denominator = 7;
    const wRatio = width/(height*denominator);
    const hRatio = height/denominator;

    this.setState({
      width: `${width - 36 - wRatio}px`,
      height: `${height - 36 - hRatio}px`
    });

   const wrapper = _.first(document.getElementsByClassName('carousel-wrapper')) as HTMLElement;
    _.assign(wrapper.style, {
      width: `${width - wRatio}px`,
      height: `${height - hRatio}px`
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
}

const mapStateToProps = (store: AppState) => ({
  userPrompt: store.config.userPrompt,
  me: store.user.me!,
  modal: store.modal!.modalDialogs.slice(-1).pop(),
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => dispatch(closeModal()),
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CarouselDialog);
