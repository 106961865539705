import React from 'react';
import iconMail from '../../assets/img/img_mailbox_orange.svg'
import iconGender from '../../assets/img/img_gender_orange.svg';
import iconCake from '../../assets/img/img_cake_orange.svg';
import iconLocation from '../../assets/img/img_map_orange.svg';
import iconLocationConfirmation from '../../assets/img/img_map_green.svg';
import imgCalendar from '../../assets/img/img_calendar_orange.svg';
import iconCalendar from '../../assets/img/img_calendar_green.svg';
import settingsPictogram from '../../assets/img/img_settings.svg';
import iconMailBox from '../../assets/img/img_mailbox_green.svg';
import iconEmailSaving from '../../assets/img/img_key_orange.svg';
import iconShare from '../../assets/img/img_share.svg';
import iconFriends from '../../assets/img/img_friends.svg';
import Menu from './Menu/menu.component';
import Profile from './Profile/profile.component';
import MailSentConfimation from './Confirmations/MailSentConfirmation/mail-sent-confirmation.component';
import AttachLocationDialog from './Profile/AttachLocationDialog/attach-location-dialog.component';
import SelectSportMomentsDialog from './Profile/SelectSportMomentsDialog/select-sport-moments-dialog.component';
import SportMomentsSavedConfirmation from './Confirmations/SportMomentsSavedConfirmation/sport-moments-saved-confirmation.components';
import AttachEmailDialog from './Profile/AttachEmailDialog/attach-email-dialog.component';
import AttachBirthYearDialog from './Profile/AttachBirthYearDialog/attach-birth-year-dialog';
import SelectGenderDialog from './Profile/GenderSelectionDialog/gender-selection-dialog.component';
import SignInByEmailDialog from './SignInByEmailDialog/sign-in-email-dialog.component';
import AttachedLocationConfirmation from './Confirmations/LocationAttachedConfirmation/location-attached-confirmation.component';
import ShareDialog from './Profile/Share/share.component';
import OnboardingShareDialog from './Onboarding/Share/share.component';
import FriendsDialog from './FriendsDialog/friends-dialog.component';
import GoToAppDialog from './Onboarding/GoToAppDialog/go-to-app-dialog.component';
import SportLinkedDialog from './Confirmations/SportLinked/sport-linked.component';
import NewFriendDialog from './Confirmations/NewFriend/new-friend.component';
import AvatarDialog from './AvatarDialog/avatar-dialog.component';
import IdentityDialog from './Onboarding/IdentityDialog/identity-dialog.component';
import NicknameDialog from './Onboarding/NicknameDialog/nickname-dialog.component';
import WelcomeDialog from './Onboarding/Welcome/welcome-dialog.component';
import PasswordDialog from './Onboarding/PasswordDialog/password-dialog.component';
import { IModalDialog } from '../../store/modal/modal.reducer';
import ErrorDialog from './ErrorDialog/error-dialog.component';
import SplitPromptDialog from './Onboarding/Splitted/split-prompt-dialog.component';
import EmailOnboardingDialog from './Onboarding/EmailDialog/email-dialog.component';
import CarouselDialog from './Onboarding/Carousel/carousel.component';
import { IUser } from '../../types/user';
import { ISport, SportPreferences } from '../../types/sport';
import {IUserPrompt} from "../../types/config";
import gotoappIcon from "../../assets/img/icn_gotoapp.png";
import WeeklyHabitDialog from "./Onboarding/WeeklyHabitDialog/weekly-habit-dialog.component";
import WeeklyTargetDialog from "./Onboarding/WeeklyTargetDialog/weekly-target-dialog.component";
import EmailOnboardingTrainersDialog from "./Onboarding/EmailOnboardingTrainers/email-trainers-dialog.component";

export const menuDialog: IModalDialog = {
  style: {
    withCloseBtn: true,
    pictogram: settingsPictogram
  },
  modalComponent: React.createElement(Menu)
};
export const profileDialog: IModalDialog = {
  style: {
    withCloseBtn: true,
    dialogClass: 'profileDialog'
  },
  modalComponent: React.createElement(Profile)
};
export const avatarDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    permanent: true,
    logo: true
  },
  modalComponent: React.createElement(AvatarDialog, { dialog })
});
export const buildCarouselDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    permanent: true,
    wrapperClass: 'carousel-wrapper',
    dialogClass: 'carousel-dialog'
  },
  modalComponent: React.createElement(CarouselDialog, { dialog })
});
export const buildNewFriendDialog = (friend: IUser, inSequence: boolean): IModalDialog => ({
  inSequence,
  style: {
    dialogClass: 'friend-dialog'
  },
  modalComponent: React.createElement(NewFriendDialog, { friend })
});
export const weeklyHabitDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    permanent: true,
    onboarding: true,
    logo: true
  },
  modalComponent: React.createElement(WeeklyHabitDialog, { dialog })
});
export const weeklyTargetDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    permanent: true,
    onboarding: true,
    logo: true
  },
  modalComponent: React.createElement(WeeklyTargetDialog, { dialog })
});
export const buildSportLinkedDialog = (
  linkedFriends: IUser[],
  selectedSport: ISport,
  sportPreference: SportPreferences
): IModalDialog => ({
  style: {
    withCloseBtn: true,
    dialogClass: 'sport-linked-dialog'
  },
  modalComponent: React.createElement(SportLinkedDialog, { linkedFriends, selectedSport, sportPreference })
});
export const attachEmailDialog  = (permanent: boolean = false): IModalDialog => ({
  style: {
    pictogram: iconMail,
    withCloseBtn: !permanent,
    permanent: permanent
  },
  modalComponent: React.createElement(AttachEmailDialog)
});
export const nicknameDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    permanent: true,
    onboarding: true,
    logo: true
  },
  modalComponent: React.createElement(NicknameDialog, { dialog })
});
export const splitPromptDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    permanent: true,
    onboarding: true,
    logo: true
  },
  modalComponent: React.createElement(SplitPromptDialog, { dialog })
});
export const welcomeDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    permanent: true,
    onboarding: true,
    logo: true
  },
  modalComponent: React.createElement(WelcomeDialog, { dialog })
});
export const identityDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    pictogram: iconMail,
    permanent: true,
    logo: true
  },
  modalComponent: React.createElement(IdentityDialog, { dialog })
});
export const genderSelectionDialog: IModalDialog = {
  style: {
    pictogram: iconGender,
    withCloseBtn: true
  },
  modalComponent: React.createElement(SelectGenderDialog)
};
export const attachBirthYearDialog: IModalDialog = {
  style: {
    pictogram: iconCake,
    withCloseBtn: true
  },
  modalComponent: React.createElement(AttachBirthYearDialog)
};
export const attachLocationDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    pictogram: iconLocation,
    permanent: true,
    logo: true
  },
  modalComponent: React.createElement(AttachLocationDialog,  { dialog })
});
export const selectSportMomentsDialog: IModalDialog = {
  style: {
    pictogram: imgCalendar,
    permanent: true,
    bgClass: 'background--white',
  },
  modalComponent: React.createElement(SelectSportMomentsDialog)
};
export const sportMomentSavedConfitmation: IModalDialog = {
  style: {
    pictogram: iconCalendar,
    permanent: true,
  },
  modalComponent: React.createElement(SportMomentsSavedConfirmation)
};
export const mailSendConfirmation = (email: string, isFullscreen?: boolean, cb?: () => void): IModalDialog => ({
  style: {
    pictogram: iconMailBox,
    bgClass: isFullscreen ? 'background--white' : ''
  },
  modalComponent: React.createElement(MailSentConfimation, { email, cb })
});
export const signInDialog: IModalDialog = {
  style: {
    withCloseBtn: true,
    pictogram: iconEmailSaving
  },
  modalComponent: React.createElement(SignInByEmailDialog)
};
export const shareDialog: IModalDialog = {
  style: {
    withCloseBtn: true,
    pictogram: iconShare
  },
  modalComponent: React.createElement(ShareDialog)
};
export const onboardingShareDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    permanent: true,
    onboarding: true,
    logo: true
  },
  modalComponent: React.createElement(OnboardingShareDialog, { dialog })
});
export const friendsDialog = (inSequence: boolean = false): IModalDialog => ({
  inSequence,
  style: {
    bgClass: 'friend-list-dialog background--white',
    permanent: true
  },
  modalComponent: React.createElement(FriendsDialog)
});
export const goToAppDialog = (dialog: IUserPrompt | null, onboarding: boolean = true): IModalDialog => ({
  style: {
    bgClass: onboarding ? 'background--white' : '',
    permanent: onboarding,
    withCloseBtn: !onboarding,
    logo: onboarding,
    pictogramWrapperClass: 'pictogram-wrapper',
    pictogram: gotoappIcon//iconShare
  },
  modalComponent: React.createElement(GoToAppDialog, { dialog, skip: onboarding })
});
export const errorDialog = (errorMessage: string, isFullScreen: boolean = false, onClose?: () => void): IModalDialog => ({
  style: {
    withCloseBtn: !isFullScreen,
    bgClass: isFullScreen ? 'background--white' : '',
    permanent: isFullScreen
  },
  modalComponent: React.createElement(ErrorDialog, { errorMessage, isFullScreen, onClose })
});
export const emailOnboardingDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    permanent: true,
    onboarding: true,
    logo: true
  },
  modalComponent: React.createElement(EmailOnboardingDialog, { dialog })
});
export const emailOnboardingTrainersDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    bgClass: 'background--white',
    permanent: true,
    onboarding: true,
    logo: true
  },
  modalComponent: React.createElement(EmailOnboardingTrainersDialog, { dialog })
});
export const passwordDialog = (dialog: IUserPrompt): IModalDialog => ({
  style: {
    pictogram: iconEmailSaving,
    bgClass: 'background--white',
    permanent: true,
    logo: true
  },
  modalComponent: React.createElement(PasswordDialog, { dialog })
});
