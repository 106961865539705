import React, {useState} from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { closeModal } from '../../../../store/modal/modal.actions';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './password-dialog.component.scss';
import { ITranslation, IUserPrompt } from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import { updateProfile } from '../../../../store/user/user.actions';
import { IUser } from '../../../../types/user';

interface IdentityDialogProps {
    translation: ITranslation[];
    closeModal: () => void;
    updateProfile: (profile: Partial<IUser>) => void;
    dialog: IUserPrompt;
    me: IUser,
}

const NicknameDialog: React.FC<IdentityDialogProps> = (props: IdentityDialogProps) => {
    const [ password, changePassword ] = useState('');
    const [ visibility, changeVisibility ] = useState(false);

    const validatePassword = () => {
        return !!password.trim() && password.length > 6;
    };
    const {
        password_prompt_button,
        password_prompt_sub,
        password_prompt_title,
        password_prompt_placeholder
    } = findTranslation(props.translation, [
        'password_prompt_button',
        'password_prompt_sub',
        'password_prompt_title',
        'password_prompt_placeholder'
    ]);

    const click = async () => {
        await props.updateProfile({ password, prompt: props.dialog.order + 1 })
    };

    return (
        <div className="password-dialog">
            <div className="formatted-text">
                {password_prompt_title}
            </div>
            <div className="position-relative">
                <input
                    id="password"
                    type={ !visibility ? 'password' : 'text' }
                    className="form-field"
                    placeholder={password_prompt_placeholder || 'Enter password'}
                    value={password}
                    onChange={e => changePassword(e.target.value)} />
                <div className="password-visibility"
                     onClick={() => changeVisibility(!visibility)}>
                    { !visibility ? <FaEye /> : <FaEyeSlash /> }
                </div>
                <button
                    className="form-button"
                    disabled={!validatePassword()}
                    onClick={() => click()}>
                    {password_prompt_button}
                </button>
            </div>
            <p className="formatted-text">
                {password_prompt_sub}
            </p>
        </div>
    );
};

const mapStateToProps = (store: AppState) => ({
    me: store.user.me!,
    translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NicknameDialog);
