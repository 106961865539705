import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { closeModal, openModal } from '../../../../store/modal/modal.actions';
import './split-prompt-dialog.component.scss';
import { ITranslation, IUserPrompt } from '../../../../types/config';
import { IUser } from "../../../../types/user";
import { updateProfile } from "../../../../store/user/user.actions";
import { openSubModalInv } from '../../../../store/modal/modal.actions';
import { IModalDialog } from "../../../../store/modal/modal.reducer";

interface SplitPrompt1DialogProps {
  translation: ITranslation[];
  dialog: IUserPrompt;
  closeModal: () => void;
  updateProfile: (profile: Partial<IUser>) => void;
}

const SplitPromptDialog: React.FC<SplitPrompt1DialogProps> = (props: SplitPrompt1DialogProps) => {
  const translations = props.translation
      .filter(item => item.locator.includes(props.dialog.prompt));

  const volume: any = {};
  translations.forEach(item => {
    volume[item.locator] = item.value;
  });

  const click = (next: number) => {
    props.updateProfile({ prompt: next });
  };

  const dialog = (key: string) => volume[`${props.dialog.prompt}_${key}`];
  const withLink = props.dialog.prompt.includes('withlink');
  const btnClasses = [
    'form-button mt-4 ',
    withLink ? 'form-button--link' : 'form-button--rect form-button--orange'
  ].join('');

  return (
    <div className="split-prompt-dialog">
      <div className="formatted-text">
        {dialog('title')}
      </div>
      <p className="formatted-text">
        {dialog('sub')}
      </p>
      <div className="button__container">
        <button className="form-button form-button--rect form-button--orange"
          onClick={() => click(props.dialog.option_1_order)}>
          {dialog('button_1')}
        </button>
        <button className={btnClasses}
          onClick={() => click(props.dialog.option_2_order)}>
          {dialog('button_2')}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (store: AppState) => ({
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
  openSubModalInv: (modalProps: IModalDialog) => dispatch(openSubModalInv(modalProps)),
  closeModal: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitPromptDialog);
