import { Dispatch } from "redux";
import { SPORT_LIST_TRANSLATED, SPORT_NEXT_TRY_SPORT } from '../action-types';
import { SportRepo } from "./sport.repository";
import { handleError } from '../../utils/handleErrors';

export const fetchTranslatedSportList = (language: string): any => (
  async (dispatch: Dispatch) => {
    try {
      const sportList = await SportRepo.listTranslated(language);
      dispatch({
        type: SPORT_LIST_TRANSLATED,
        payload: { sportList }
      });
    } catch (error) {
      dispatch(handleError(error))
    }
  }
)

export const fethchNextTrySport = (language: string): any => (
  async (dispatch: Dispatch) => {
    try {
      const trySport = await SportRepo.trySport(language);
      dispatch({
        type: SPORT_NEXT_TRY_SPORT,
        payload: { trySport }
      })
    } catch (error) {
      dispatch(handleError(error));
    }
  }
)