import React from 'react';
import {IRangeProps} from '../../types/config';

interface ISliderAreaProps {
    title: string,
    value: number,
    onChange: Function,
    range: IRangeProps
}

export class SliderPanel extends React.Component<ISliderAreaProps, any> {
    render () {
        return (
            <div className="rotation-item">
                <div className="rotation-item__measure">
                    <div>{ this.props.title }</div>
                    <input
                        className="rotation__input"
                        value={this.props.value.toString()}
                        onChange={e => this.props.onChange(Number(e.target.value))}/>
                </div>
                <input
                    className="slider"
                    type="range"
                    min={this.props.range.min}
                    max={this.props.range.max}
                    step={this.props.range.step}
                    value={this.props.value}
                    onChange={e => this.props.onChange(Number(e.target.value))}/>

            </div>
        )
    }
}