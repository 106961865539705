import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../../../store';
import { closeModal } from '../../../../store/modal/modal.actions';
import { updateProfile } from '../../../../store/user/user.actions';
import { IUser } from '../../../../types/user';
import './share.component.scss';
import { ITranslation } from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';

interface IShareDialogProps {
  me: IUser;
  translation: ITranslation[];
  updateFirstName: (firstName: string) => void;
  closeModal: () => void;
}
const ShareDialog: React.FC<IShareDialogProps> = (props: IShareDialogProps) => {
  const [ firstName, setFirstName ] = useState(props.me.first_name);
  const onSave = () => {
    props.updateFirstName(firstName);

    const { share_social } = findTranslation(props.translation, ['share_social']);

    const shareMessage = share_social
        .replace(/https:\/\/[^\s|]+/, `$&?user=${props.me.username}&language=${props.me.language}`)
        .replace('<code>', props.me.username);

    const navigator: any = window.navigator;
    if (navigator && navigator.share) {
      navigator.share({
        title: 'Share',
        text: shareMessage,
      });
    }

    props.closeModal();
  };

  const {
    share_title,
    share_sub,
    share_placeholder,
    share_button,
  } = findTranslation(props.translation, [
    'share_title',
    'share_sub',
    'share_placeholder',
    'share_button',
  ]);

  return (
      <div className="share-dialog">
        <div className="title">
          {share_title}
        </div>

        <p className="dialog-description">
          {share_sub}
        </p>

        <div>
          <input
              className="form-field"
              placeholder={share_placeholder}
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName || ''}
          />
          <div className="share-code">
            {props.me.username}
          </div>
          <button
              className="form-button"
              onClick={onSave}>
            {share_button}
          </button>
        </div>
      </div>
  );
};

const mapStateToProps = (store: AppState) => ({
  me: store.user.me!,
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateFirstName: (first_name: string) => dispatch(updateProfile({ first_name })),
  closeModal: () => dispatch(closeModal())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShareDialog);
