export interface ISport {
  id?: number;
  export_name: string;
  name: string;
  priority: number;
  url: string;
  images: IPicture[];
  icons: IIcon[];
}

export interface IPicture {
  id?: number;
  picture: string;
  sport?: number;
}

export interface IIcon {
  id?: number;
  icon: string;
  sport: number;
  color: IconColors;
}

export interface ICustomLabels {
  label: string;
  value: SportPreferences;
}

export interface ISportCategory {
  label: string;
  sports: ISport[];
  color: IconColors;
}

export type SportPreferences  = 'not_interested' | 'like_to_try' | 'already_played' | 'undo';
export type IconColors = 'black' | 'green' | 'blue';

export const LIKE_TO_TRY = 'like_to_try';
export const ALREADY_PLAYED = 'already_played';
export const NOT_INTERESTED = 'not_interested';
