import { IUser, IUserRequestParams } from '../../types/user';
import { UserRepo } from './user.repository';
import { Dispatch } from 'redux';
import { USER_CREATED, WHOAMI, USER_UPDATED, AVATAR_UPDATED, SPORT_ADDED } from '../action-types';
import { store } from '..';
import { handleError } from '../../utils/handleErrors';
import { SportPreferences } from '../../types/sport';
import { IUserCreatePayload } from '../../types/user';
import { closeModal, openModal } from '../modal/modal.actions';
import { mailSendConfirmation } from "../../components/Modal/dialogs";

export const createUser = (language: string, email?: string, invited_by?: string, isOnLanding?: boolean, params?: IUserRequestParams): any => (
  async (dispatch: Dispatch) => {
    try {
      const payload: IUserCreatePayload = { language, email, invited_by };
      const me = await UserRepo.create(payload, params);
      dispatch({
        type: USER_CREATED,
        payload: { me }
      });
    } catch (error) {
      //todo: check by error code not by error message
      if (isOnLanding && email && error.response.status === 400 && error.response.data.detail && error.response.data.detail === `Email already registered in create '${email}'`) {
          const modal = mailSendConfirmation(email);
          dispatch(openModal(modal));
      } else {
          dispatch(handleError(error))
      }
    }
  }
);

export const whoami = (): any => (
  async (dispatch: Dispatch) => {
    try {
      const mePartial = await UserRepo.getMe();
      const me = await UserRepo.retreive(mePartial.username);
      dispatch({
        type: WHOAMI,
        payload: { me }
      })
    } catch (error) {
      dispatch(handleError(error))
    }
  }
);

export const updateProfile = (changes: Partial<IUser>, leave?: boolean, params?: IUserRequestParams): any => (
  async (dispatch: Dispatch) => {
    const username = store.getState().user.me!.username;
    const me = await UserRepo.update(username, changes, params);

    try {
      if (changes.hasOwnProperty('prompt')) {
          const { userPrompt } = store.getState().config;
          const last = userPrompt.slice(-1).pop()
          if (last) {
              changes.prompt = Number(last.order) === Number(changes.prompt) ?
                  me.prompt : changes.prompt
          }
      }

      dispatch({
        type: USER_UPDATED,
        payload: { me }
      });

      if (!leave) {
          dispatch(closeModal());
      }
    } catch (error) {
      dispatch(handleError(error))
    }
  }
);

export const updateAvatar = (avatar: FormData): any => (
  async (dispatch: Dispatch) => {
    try {
      const me = await UserRepo.uploadAvatar(avatar);
      dispatch({
        type: AVATAR_UPDATED,
        payload: { me }
      });
      return me;
    } catch (error) {
      dispatch(handleError(error))
    }
  }
);

export const addSport = (sportId: number, pref: SportPreferences): any => (
  async (dispatch: Dispatch) => {
    try {
      const sport = await UserRepo.addSport(sportId, pref);
      dispatch({
        type: SPORT_ADDED,
        payload: { sport }
      });
    } catch (error) {
      dispatch(handleError(error))
    }
  }
);

export const setEmail = (email: string, password: string = '', confirm: boolean = true, confirmCb?: () => void, errCb?: () => void): any => (
    async (dispatch: Dispatch) => {
        try {
            const response = await UserRepo.restoreAccountByEmail(email, password);
            if (confirm) {
                const modal = mailSendConfirmation(email, true, confirmCb);
                dispatch(openModal(modal));
            }
            return response
        } catch (error) {
            dispatch(handleError(error, errCb));
        }
    }
);

export const setIdentity = (email: string, password: string = '', confirm: boolean = true, confirmCb?: () => void, errCb?: () => void): any => (
    async (dispatch: Dispatch) => {
        try {
            return await UserRepo.refreshCredentials({ email, password });
        } catch (error) {
            dispatch(handleError(error, errCb));
        }
    }
);