import { Dispatch } from "redux";
import { ISportMoment } from "../../types/sport-moment";
import { SportMomentRepo } from './sport-moment.repository';
import { SPORT_MOMENT_CREATED, SPORT_MOMENT_DELETED, SPORT_MOMENTS_LIST, SPORT_MOMENTS_CREATED } from '../action-types';
import { store } from '../index';
import { handleError } from '../../utils/handleErrors';

export const listSportMoments = (): any => (
  async (dispatch: Dispatch) => {
    try {
      const sportMoments = await SportMomentRepo.list();
      dispatch({
        type: SPORT_MOMENTS_LIST,
        payload: { sportMoments }
      })
    } catch (error) {
      dispatch(handleError(error))
    }
  }
)

export const burstCreateSportMoments = (sportMoments: ISportMoment[]): any => (
  async (dispatch: Dispatch) => {
    try {
      const user = store.getState().user.me!;
      const createdSportMoments = await SportMomentRepo.burstCreate(sportMoments, user);
      dispatch({
        type: SPORT_MOMENTS_CREATED,
        payload: { sportMoments: createdSportMoments }
      });
    } catch (error) {
      dispatch(handleError(error))
    }
  }
)

export const createSportMoments = (sportMoments: ISportMoment[]): any => (
  async (dispatch: Dispatch) => {
    try {
      const user = store.getState().user.me!;
      const createdSportMoments = await SportMomentRepo.create(sportMoments, user);
      dispatch({
        type: SPORT_MOMENT_CREATED,
        payload: { sportMoments: createdSportMoments }
      });
    } catch (error) {
      dispatch(handleError(error))
    }
  }
)

export const deleteSportMoemnts = (sportMoment: ISportMoment): any => (
  async (dispatch: Dispatch) => {
    try {
      await SportMomentRepo.delete(sportMoment.id!);
      const deletedSportMoment = store.getState().sportMoments.sportMoments
        .filter(moment => moment.id !== sportMoment.id)
      dispatch({
        type: SPORT_MOMENT_DELETED,
        payload: { SportMoments: deletedSportMoment }
      })
    } catch (error) {
      dispatch(handleError(error))
    }
  }
)