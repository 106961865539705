import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { closeModal, openModal } from '../../../../store/modal/modal.actions';
import './go-to-app-dialog.component.scss';
import {ITranslation, IUserPrompt} from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';

import appleBadge from '../../../../assets/img/apple-badge.png';
import googleBadge from '../../../../assets/img/google-badge.png';
import {IUser} from "../../../../types/user";
import {updateProfile} from "../../../../store/user/user.actions";

interface IFriendsDialogProps {
  translation: ITranslation[];
  dialog: IUserPrompt | null;
  skip: boolean;
  updateProfile: (profile: Partial<IUser>) => void;
  closeModal: () => void;
}

class GoToAppDialog extends React.Component<IFriendsDialogProps> {
  render() {
    let {
      gotoapp_prompt_title,
      gotoapp_prompt_sub,
      gotoapp_prompt_android,
      gotoapp_prompt_apple,
      gotoapp_prompt_skip,
      gotoapp_prompt_button_apple,
      gotoapp_prompt_button_android
    } = findTranslation(this.props.translation, [
      'gotoapp_prompt_title',
      'gotoapp_prompt_sub',
      'gotoapp_prompt_android',
      'gotoapp_prompt_apple',
      'gotoapp_prompt_skip',
      'gotoapp_prompt_button_apple',
      'gotoapp_prompt_button_android'
    ]);

    const submit = () => {
      if (this.props.dialog !== null) {
        this.props.updateProfile({
          prompt: Number(this.props.dialog.order) + 1
        });
      } else {
        this.props.closeModal();
      }
    };

    const goTo = () => {
      this.props.updateProfile({
        prompt: 55
      });
    }

    return (
      <div className="gotoapp-dialog">
        <div className="title">
          {gotoapp_prompt_title }
        </div>

        <p className="dialog-description">
          {gotoapp_prompt_sub }
        </p>

        <div className="form-field">
          <a href={gotoapp_prompt_android} target="_blank" className="badge-link" onClick={ goTo }>
            <img src={gotoapp_prompt_button_android} />
          </a>
          <a href={gotoapp_prompt_apple} target="_blank" className="badge-link" onClick={ goTo }>
            <img src={gotoapp_prompt_button_apple} />
          </a>
        </div>

        { this.props.skip &&
          <span onClick={() => submit()} className="form-button--link">
            { gotoapp_prompt_skip }
          </span> }
      </div>
    )
  };
}

const mapStateToProps = (store: AppState) => ({
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
  closeModal: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoToAppDialog);
