import React, {useEffect, useState} from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { closeModal } from '../../../../store/modal/modal.actions';
import './weekly-target-dialog.component.scss';
import {ITranslation, IUserPrompt} from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import { updateProfile } from '../../../../store/user/user.actions';
import { IUser } from '../../../../types/user';

interface WeeklyTargetDialogProps {
    translation: ITranslation[];
    closeModal: () => void;
    updateProfile: (profile: Partial<IUser>) => void;
    dialog: IUserPrompt;
    me: IUser,
}

const WeeklyTargetDialog: React.FC<WeeklyTargetDialogProps> = (props: WeeklyTargetDialogProps) => {
    const [ frequency, changeFrequency ] = useState<number>(1);
    const {
        weekly_target_prompt_title,
        weekly_target_prompt_sub,
        weekly_target_prompt_button
    } = findTranslation(props.translation, [
        'weekly_target_prompt_title',
        'weekly_target_prompt_sub',
        'weekly_target_prompt_button'
    ]);

    useEffect(() => {
        /*const sheet = document.createElement('style');
        sheet.textContent = '.range input::' + '-webkit-slider-runnable-track' + '{background: linear-gradient(to right, #37adbf 0%, #37adbf 50.000000001000004%, #fff 50.000000001000004%, #fff 100%)}';
        document.body.appendChild(sheet);*/
    });

    const options = new Array(8)
        .fill(null)
        .map((item, key) => {
            const option = {
                value: key < 7 ? key + 1 : 8,
                text: key < 7 ? key + 1 : '> 7'
            };
            return <li onClick={() => changeFrequency(option.value)} key={option.value} className={`
                range-labels__item
                ${option.value === frequency ? 'active' : ''}
            `}>{ option.text }</li>
        });


    const updateUsername = async () => {
        await props.updateProfile({
            weekly_target: Number(frequency),
            prompt: Number(props.dialog.order) + 1
        });
    };

    return (
        <div className="weekly-target-dialog">
            <div className="formatted-text">
                "{weekly_target_prompt_title}"
            </div>
            <p className="formatted-text">
                {weekly_target_prompt_sub}
            </p>
            <div className="position-relative">
                <ul className="range-labels">
                    { options }
                </ul>
                <div className="range">
                    <input type="range" min={1} max={8} value={frequency} className={'range-input'}
                           onChange={e => changeFrequency(Number(e.target.value))} />
                </div>
                <div className="people-flat-icon--1" />
                <button
                    className="form-button"
                    disabled={!frequency}
                    onClick={() => updateUsername()}>
                    { weekly_target_prompt_button || 'OK'}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (store: AppState) => ({
    me: store.user.me!,
    translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WeeklyTargetDialog);
