import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { closeModal } from '../../../../store/modal/modal.actions';
import iconMale from '../../../../assets/img/img_male.svg'
import iconFemale from '../../../../assets/img/img_female.svg';
import { updateProfile } from '../../../../store/user/user.actions';
import './gender-selection-dialog.component.scss';
import { genderStates, Gender } from '../../../../types/user';
import { AppState } from '../../../../store';
import { ITranslation } from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';

interface IGenderSelectionDialogProps {
  translation: ITranslation[];
  updateGender: (gender: Gender) => void;
  onClose: () => void;
}
const GenderSelectionDialog: React.FC<IGenderSelectionDialogProps> = (props: IGenderSelectionDialogProps) => {
  const [ selectedGender, setGender ] = useState('');

  const genderButton = (icon: string, gender: string, genderOriginal: string) => {
    const iconActive = selectedGender === gender ? 'icon-active' : '';

    return (
      <div className="gender-button" onClick={() => setGender(genderOriginal)}>
        <div className={`${gender}-btn gender-icon ${iconActive}`}>
          <img src={icon} alt="gender-icon"/>
        </div>
        {gender}
      </div>
    )
  };

  const onUpdateGender = (targetGender: string) => {
    props.updateGender(targetGender as Gender);
    props.onClose();
  };
  
  const {
    gender_prompt_title,
    gender_prompt_or,
    common_ok_button,
    gender_prompt_sub,
    gender_prompt_male,
    gender_prompt_female
  } = findTranslation(props.translation, [
    'gender_prompt_title',
    'gender_prompt_or',
    'common_ok_button',
    'gender_prompt_sub',
    'gender_prompt_male',
    'gender_prompt_female'
  ]);
  
  return (
    <div className="gender-selection">
      <p>{gender_prompt_title}</p>

      {genderButton(iconMale, gender_prompt_male, 'male')}
      <span className="separate-word">{gender_prompt_or}</span>
      {genderButton(iconFemale, gender_prompt_female, 'female')}

      <button 
        className="form-button"
        onClick={() => onUpdateGender(selectedGender)}
        disabled={!selectedGender}
      >
        {common_ok_button}
      </button>
      <div 
        className="link-button"
        onClick={() => onUpdateGender(genderStates.HIDDEN)}
      >
        {gender_prompt_sub}
      </div>
    </div>
  )
}

const mapStateToProps = (store: AppState) => ({
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateGender: (gender: Gender) => dispatch(updateProfile({ gender })),
  onClose: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenderSelectionDialog);
