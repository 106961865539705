import {ICroppedAreaPixelsProps} from "../../types/config";

export function Drawer (cb: Function, file: File | null) {
    const init = async (loadedImage: string, croppedAreaPixels: ICroppedAreaPixelsProps, rotation: number) => {
        const image: CanvasImageSource = new Image();
        image.src = loadedImage

        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;

        const { width, height, x, y } = croppedAreaPixels
        const safeArea = Math.max(image.width, image.height)

        canvas.width = safeArea
        canvas.height = safeArea

        ctx.translate(safeArea / 2, safeArea / 2)
        ctx.rotate(rotation * Math.PI / 180)
        ctx.translate(-safeArea / 2, -safeArea / 2)

        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        )

        const data = ctx.getImageData(0, 0, safeArea, safeArea)
        canvas.width = width
        canvas.height = height

        ctx.putImageData(
            data,
            0 - safeArea / 2 + image.width * 0.5 - x,
            0 - safeArea / 2 + image.height * 0.5 - y
        )

        // @ts-ignore
        if (canvas.msToBlob) {
            // @ts-ignore
            const blob = canvas.msToBlob()
            await toBlob(blob)
        } else {
            await canvas.toBlob(toBlob);
        }
    }

    const toBlob = async (blob: Blob | null) => {
        const shards = await new Response(blob).arrayBuffer()
        let response;

        try {
            response = new File([shards] as BlobPart[], file!.name, {
                lastModified: Date.now(),
                type: file!.type
            })
        } catch (e) {
            response = new Blob([shards] as BlobPart[])
            Object.assign(response, {
                lastModified: Date.now(),
                type: file!.type,
                name: file!.name
            })
        }

        await cb(response as File)
    }

    return init
}
