import React, {useState} from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import logo from '../../assets/img/img_logo.svg'
import gbLang from '../../assets/img/icn_flag_gb.svg';
import nlLang from '../../assets/img/icn_flag_nl.svg';
import { IModalDialog } from '../../store/modal/modal.reducer';
import { openModal } from '../../store/modal/modal.actions';
import {createUser, updateProfile, whoami} from '../../store/user/user.actions';
import { signInDialog } from '../../components/Modal/dialogs';
import { Localization } from '../../utils/storageManager';
import { fetchTranslation } from '../../store/config/config.actions';
import { ITranslation } from '../../types/config';
import './landing-page.component.scss';
import { AppState } from '../../store';
import { findTranslation } from '../../utils/findTranslation';

interface ILandingPageProps extends RouteComponentProps {
  translation: ITranslation[];
  fetchTranslation: (language: string) => void;
  openModal: (modalProps: IModalDialog) => void;
  onCreateUser: (language: string, email: string) => void;
  whoami: () => void;
}

const LandingPage: React.FC<ILandingPageProps> = (props: ILandingPageProps) => {
  const [email, setEmail] = useState('');
  const onSelectLanguage = async (language: string) => {
    await props.onCreateUser(language, email);

    const prevLang = Localization.getLanguage();
    if (prevLang !== language) {
      Localization.setLanguage(language);
      props.fetchTranslation(language!);
    }
  };

  const {
    landing_main_title,
    landing_sub_title,
    landing_content_title,
    landing_content,
    menu_login,
    email_prompt_2_form
  } = findTranslation(props.translation, [
    'landing_main_title',
    'landing_sub_title',
    'landing_content_title',
    'landing_content',
    'menu_login',
    'email_prompt_2_form'
  ]);

  return (
    <div>
      <div className="header">
        <div className="title-block">
          <div>{landing_main_title}</div>
          <img className="logo" src={logo} alt="logo"/>
          <div>{landing_sub_title}</div>
        </div>
      </div>

      <div className="main-block">
        <div className="content">
          <p className="title">{landing_content_title}</p>
          <hr/>

          <div>
            <p className="label">{email_prompt_2_form}</p>
            <div>
              <input
                  id="email"
                  className="form-field"
                  placeholder="Type your email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}/>
            </div>
          </div>
          <div className="language-block">
            <p>{landing_content}</p>
            <div>
              <img
                onClick={() => onSelectLanguage('en')}
                src={gbLang}
                className={`lang-icon ${!email ? 'disabled' : ''}`}
                alt="gb-lang"
              />
              <img
                className={`lang-icon ${!email ? 'disabled' : ''}`}
                onClick={() => onSelectLanguage('nl')}
                src={nlLang}
                alt="nl-lang"/>
            </div>
          </div>

          <div className="email-block">
            <button className="link-button" onClick={() => props.openModal(signInDialog)}>
              {menu_login}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const maptStateToProps = (store: AppState) => ({
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTranslation: (language: string) => dispatch(fetchTranslation(language)),
  openModal: (modalProps: IModalDialog) => dispatch(openModal(modalProps)),
  onCreateUser: (language: string, email: string) => dispatch(createUser(language, email, '', true, { emailType: 'play' })),
  whoami: () => dispatch(whoami())
});

export default connect(
  maptStateToProps,
  mapDispatchToProps
)(withRouter(LandingPage));
