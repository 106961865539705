import { IRelatedSports, IUser } from '../types/user';
import { ICustomLabels, ISport, LIKE_TO_TRY, ISportCategory } from '../types/sport';

import * as _ from 'lodash';

export const assocSportsWithUser = (
  user: IUser,
  sportList: ISport[],
  sportCategories: ICustomLabels[],
  me: IUser,
  ordered: Boolean
): ISportCategory[] => 
  sportCategories.map((type: ICustomLabels) => {
    let sports = _.chain(user.sports)
        .map((sport: IRelatedSports) => {
            if (sport.result === type.value) {
                return sportList.find(({ id }) => {
                    let sportId = sport!.sport === id!;
                    let matched = me.sports.find(item => item.sport === id);

                    return sportId && matched;
                });
            }
        })
        .filter((sport?: ISport) => !!sport)
        .value();

    if (ordered) {
        sports = _.orderBy(sports, (sport?: ISport) => sport!.name, ['asc']);
    }

    return {
      label: type.label,
      sports: sports as ISport[],
      color: type.value === LIKE_TO_TRY ? 'green' : 'blue'
    }
  });
