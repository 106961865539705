import React from 'react';
import queryString from 'query-string';
import { Route, RouteProps, Redirect } from 'react-router';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { IUser } from '../../types/user';
import { createUser, updateProfile, whoami } from '../../store/user/user.actions';
import {addFriend, fetchFriends} from '../../store/friends/friends.actions';
import {CredentialsManager, Localization} from '../../utils/storageManager';
import _ from 'lodash';
import { IModalDialog } from '../../store/modal/modal.reducer';
import { openSubModalInv } from '../../store/modal/modal.actions';
import { friendsDialog } from '../Modal/dialogs';
import { fetchTranslation } from '../../store/config/config.actions';

interface IRouteGuardProps extends RouteProps {
  mixed?: boolean;
  guest?: boolean;
  private?: boolean;
  friends?: boolean;
  friendList: IUser[];
  me?: IUser;
  whoami: () => void;
  computedMatch?: any;
  createUser: (language: string, invited_by: string) => void;
  fetchFriends: () => void;
  fetchTranslation: (language: string) => void;
  addFriend: (friendCode: string, inSequence: boolean) => IUser;
  openSubModalInv: (modalProps: IModalDialog) => void;
  updateProfile: (profile: Partial<IUser>) => void;
}
interface IRouteGuardState {
  loader: boolean;
}

class RouteGuard extends React.Component<IRouteGuardProps, IRouteGuardState> {
  state: IRouteGuardState = {
    loader: true
  };

  private getCredentials = async () => {
    const credentials = _.pickBy(
        CredentialsManager.getCredentials(),
        _.identity
    );

    if (!_.isEmpty(credentials)) {
      this.setState({ loader: true });
      await this.props.whoami();
    }
  };

  async componentDidMount() {
    const { token, user, language, prompt } = queryString.parse(this.props.location!.search);

    if (!!token) {
      CredentialsManager.refreshAccessToken(token as string);
    }

    await this.getCredentials();

    if (!!user! && !!language!) {
      Localization.setLanguage(language as string);
      if (!this.props.me) {
        await this.props.createUser(language as string, user as string);
      }
      await this.props.addFriend(user as string, true);
      await this.props.fetchFriends();
    }

    if (prompt) {
      await this.props.updateProfile({
        prompt: Number(prompt)
      });
    }

    const lan = (this.props.me && this.props.me.language) || 'nl'
    await this.props.fetchTranslation(lan);

    this.setState({ loader: false });
  }

  renderRoute() {
    const { token, user, language, prompt } = queryString.parse(this.props.location!.search);
    const aRoute = this.props.private || this.props.mixed;
    const naRoute = this.props.guest || this.props.mixed;

    const home = (naRoute && this.props.me) || (user && language) || token;

    const friends = this.props.location!.pathname
        .replace('/menu/friends', '');
    if (friends !== this.props.location!.pathname && this.props.me) {
      this.props.openSubModalInv(friendsDialog(true));
      return <Redirect to={friends} />;
    }
    if (home || prompt) {
      return <Redirect to='/try/home' />;
    }
    if (aRoute && !this.props.me) {
      return <Redirect to='/try' />;
    }
    if (this.props.path === '*') {
      return <Redirect to='/' />;
    }

    return <Route {...this.props} />
  }

  render() {
    return (
      <>
        { this.state.loader ? (
          <div>
            {/* TODO preloader */}
          </div>
        ) : (
          this.renderRoute()
        )}
      </>
    );
  }
}

const mapStateToProps = (store: AppState) => ({
  me: store.user.me,
  friendList: store.friends.friendList,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  whoami: () => dispatch(whoami()),
  createUser: (language: string, invited_by: string) => dispatch(createUser(language, undefined, invited_by)),
  addFriend: (friendCode: string, inSequence: boolean) => dispatch(addFriend(friendCode, inSequence)),
  openSubModalInv: (modalProps: IModalDialog) => dispatch(openSubModalInv(modalProps)),
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
  fetchFriends: () => dispatch(fetchFriends()),
  fetchTranslation: (language?: string) => dispatch(fetchTranslation(language))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteGuard);
