import React, { CSSProperties } from 'react';
import Select from 'react-select';
import { Gender } from '../../types/user';
import { SelectComponentsProps } from 'react-select/src/Select';
import { StylesConfig } from 'react-select/src/styles';
import './form-dropdown.component.scss';

interface IFormDropdownProps extends SelectComponentsProps {
  options: ISelectOption[]
}

export interface ISelectOption {
  label: string;
  value: string;
}

export const FormDropdown: React.FC<IFormDropdownProps> = (props: IFormDropdownProps) => {
  const styles: Partial<StylesConfig> = {
    control: (base: CSSProperties) => ({
      ...base,
      border: 'none',
      boxShadow: '0'
    }),
    menu: (base: CSSProperties) => ({
      ...base,
      padding: '7px 10px',
      textAlign: 'left'
    }),
    placeholder:  (base: CSSProperties) => ({
      ...base,
      color: 'rgba(63, 63, 63, 0.2)',
    }),
  };

  return (
    <div className="form-field form-dropdown">
      <Select
        {...props}
        options={props.options}
        components={{
          IndicatorSeparator: () => null
        }}
        styles={styles}
      />
    </div>
  )
};
