import _ from 'lodash';
import { IUser, IUserRequestParams } from './../../types/user';
import { CredentialsManager } from '../../utils/storageManager';
import { setAxiosHeader } from '../../utils/http';
import axios from '../../utils/http';
import { AxiosResponse, AxiosError } from 'axios';
import { SportPreferences } from '../../types/sport';
import { IUserCreatePayload } from '../../types/user';

interface TCredentials {
  token: string;
}

interface EPCredentials {
  email: string;
  password: string;
}

export class UserRepo {
  public static async create(payload: IUserCreatePayload, params?: IUserRequestParams) {
    const { data: me } = await axios.post('/user/', payload, { params: _.mapKeys(params, (v, k) => _.snakeCase(k)) });
    const { access, refresh } = me;
    CredentialsManager.saveCredentials({ access, refresh });
    setAxiosHeader(access);
    return me;
  }

  public static async retreive(username: string) {
    return axios.get(`/user/${username}`)
      .then(res => res.data);
  }

  public static async getMe() {
    const { access } = CredentialsManager.getCredentials();
    setAxiosHeader(access!);
    return axios.get('/user/whoami/')
      .then(res => res.data);
  }

  public static async update(username: string, changes: Partial<IUser> | FormData, params?: IUserRequestParams) {
    return axios.put(`/user/${username}/`, changes, { params: _.mapKeys(params, (v, k) => _.snakeCase(k)) })
      .then(res => res.data);
  }

  public static async restoreAccountByEmail(email: string, password: string) {
    return axios.post('/user/forgot/', { email, password })
        .then(res => res.data);
  }

  public static async refreshCredentials(payload: TCredentials | EPCredentials) {
    const { data: credentials } = await axios.post('/token/', payload);
    const { access, refresh } = credentials;
    CredentialsManager.saveCredentials({ access, refresh });
    setAxiosHeader(access);
    return credentials;
  }

  public static async uploadAvatar(avatar: FormData) {
    return axios.post('/user/image/', avatar)
      .then(res => res.data);
  }

  public static async addSport(sportId: number, result: SportPreferences) {
    return axios.post(`/user/sport/${sportId}/`, { result })
      .then(res => res.data);
  }

  public static async fetchFriends() {
    return axios.get('/user/friend/')
      .then(res => res.data);
  }
}
