import axios from '../../utils/http';

export class ConfigRepo {
  public static async listUserPromptOrder(language?: string) {
    return axios.get(`/config/userprompt/?lan=${language}`)
      .then( res => res.data);
  }
  public static async getUserPropmtByOrder(order: number) {
    return axios.get(`/config/userprompt/${order}`)
      .then( res => res.data);
  }
  public static async translation(language?: string) {
    return axios.get(`/config/translations/?lan=${language}`)
      .then( res => res.data);
  }
  public static async fetchCountries() {
    return axios.get(`/config/country/`)
      .then( res => res.data);
  }
}
