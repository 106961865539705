import React from 'react';
import Cropper from 'react-easy-crop';
import { ICropperProps } from '../../types/config';
import { SliderPanel } from "./SliderPanel";
import { IRangeProps } from '../../types/config';

import './editor.scss';

interface IImageEditorProps {
    image: string
}

export class ImageEditor extends React.Component <IImageEditorProps, any> {
    public state: ICropperProps = {
        crop: { x: 0, y: 0 },
        zoom: 1,
        rotation: 0,
        croppedAreaPixels: null,
        cropSize: {
            width: 200,
            height: 200
        }
    }

    private onZoomChange = (zoom: any) => {
        this.setState({
            zoom: Number(zoom.toFixed(2)),
        })
    }

    private onRotationChange = (value: number) => {
        this.setState({
            rotation: value
        })
    }

    private onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        this.setState({
            croppedAreaPixels
        })
    }

    private onCropChange = (crop: any) => {
        this.setState({
            crop
        })
    }

    render () {
        const range = (min: number, max: number, step: number): IRangeProps => {
            return { min, max, step }
        }

        return (
            <>
                <div className="rotation-panel">
                    <SliderPanel key={'rotation-slider'} title='Rotation'
                        value={this.state.rotation} onChange={this.onRotationChange} range={range(0, 360, 1)} />
                    <SliderPanel key={'zoom-slider'} title='Zoom'
                        value={this.state.zoom} onChange={this.onZoomChange} range={range(1, 3, 0.1)} />
                </div>
                <div className="crop-container--centered">
                    <div className="crop-container">
                        <Cropper
                            image={this.props.image}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={1}
                            rotation={this.state.rotation}
                            cropSize={this.state.cropSize}
                            onRotationChange={this.onRotationChange}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onZoomChange={this.onZoomChange}/>
                    </div>
                </div>
            </>
        )
    }
}