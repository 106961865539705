import React from 'react';
import {Dispatch} from 'redux';
import {closeModal} from '../../../store/modal/modal.actions';
import {connect} from 'react-redux';


interface IErrorDialog {
  errorMessage: string;
  closeModal: () => void;
  onClose?: () => void | undefined;
  isFullScreen?: boolean | undefined;
}
const ErrorDialog: React.FC<IErrorDialog> = (props: IErrorDialog) => {
    const close = () => {
        if (props.onClose) {
            props.onClose();
            return;
        }
    };
  return (
    <div>
      <div className='title'>Error has occurred</div>
      <p>{props.errorMessage}</p>
      { props.isFullScreen && (<button className='form-button' onClick={() => close()}>Ok</button>) }
    </div>
  )
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(closeModal())
});

export default connect(
    null,
    mapDispatchToProps
)(ErrorDialog);
