import { createStore, combineReducers, Store, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { modalReducer } from './modal/modal.reducer';
import { createLogger } from "redux-logger";
import { userReducer } from "./user/user.reducer";
import { sportMomentsReducer } from './sport-moment/sport-moment.reducer';
import { sportReducer } from "./sport/sport.reducer";
import { configReducer } from "./config/config.reducer";
import { friendsReducer } from "./friends/friends.reducer";

const rootReducer = combineReducers({
  modal: modalReducer,
  user: userReducer,
  sportMoments: sportMomentsReducer,
  sport: sportReducer,
  config: configReducer,
  friends: friendsReducer
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppStore = Store<AppState>;

const logger = createLogger({
  predicate: (state, action) => !action.type.includes('ping'),
});

export const store = createStore(rootReducer, applyMiddleware(thunk, logger));