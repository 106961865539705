import React from 'react';

export const NoInterestIcon: React.FC = () => {
  return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="260.000000pt" height="260.000000pt" viewBox="0 0 260.000000 260.000000"
           preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,260.000000) scale(0.100000,-0.100000)" fill="#DB3939">
              <path d="M247 2353 l-247 -248 402 -402 403 -403 -403 -403 -402 -402 247
-248 248 -247 402 402 403 403 403 -403 402 -402 248 247 247 248 -402 402
-403 403 403 403 402 402 -247 248 -248 247 -402 -402 -403 -403 -403 403
-402 402 -248 -247z" />
          </g>
      </svg>
  );
};
