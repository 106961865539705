import React from 'react';

export const LikeToTryIcon: React.FC = () => {
  return (
    <svg width="35px" height="31px" viewBox="0 0 35 31" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1.-Main-Page-Copy-7" transform="translate(-214.000000, -548.000000)" fill="#40CA9D" fillRule="nonzero">
          <g id="Group-4" transform="translate(198.000000, 527.000000)">
            <path d="M47.5806896,23.1526647 C48.9933158,24.3517542 49.9730398,25.8275578 50.5198627,27.5800734 C51.0666856,29.3325891 51.1464309,31.0966348 50.7590977,32.8722105 C50.3717645,34.6477862 49.5401387,36.181237 48.2642182,37.4725649 L35.0721158,51.3082174 C34.6164297,51.7694061 34.0923918,52 33.5,52 C32.9076082,52 32.3835703,51.7694061 31.9278842,51.3082174 L18.7357818,37.5417432 C17.4598613,36.2042971 16.6282355,34.6477862 16.2409023,32.8722105 C15.8535691,31.0966348 15.9333144,29.3325891 16.4801373,27.5800734 C17.0269602,25.8275578 18.0066842,24.3517542 19.4193104,23.1526647 C20.6496619,22.0919317 22.0395031,21.4232081 23.588835,21.1464951 C25.1381669,20.869782 26.6647138,20.9850795 28.1684768,21.4923864 C29.6722397,21.9996933 30.993728,22.8528925 32.1329428,24.0519821 L33.5,25.4355473 L34.8670572,24.0519821 C36.006272,22.8528925 37.3277603,21.9996933 38.8315232,21.4923864 C40.3352862,20.9850795 41.8618331,20.869782 43.411165,21.1464951 C44.9604969,21.4232081 46.3503381,22.0919317 47.5806896,23.1526647 Z" id="icn_heart"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
