import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IModalDialog } from '../../../store/modal/modal.reducer';
import { openModal } from '../../../store/modal/modal.actions';
import { AppState } from '../../../store';
import { ITranslation } from '../../../types/config';
import { findTranslation } from '../../../utils/findTranslation';
import './sign-in-email-dialog.component.scss';
import { setEmail } from "../../../store/user/user.actions";

interface ISignInByEmailDialogProps {
  translation: ITranslation[];
  openModal: (modalProps: IModalDialog) => void;
  setEmail: (email: string) => void;
}

const SignInByEmailDialog: React.FC<ISignInByEmailDialogProps> = (props: ISignInByEmailDialogProps) => {
  const [ email, changeEmail ] = useState('');
  const {
    email_prompt_1_title,
    email_prompt_1_sub,
    email_prompt_1_form,
    common_ok_button,
    email_prompt_1_bottom_1,
    email_prompt_1_bottom_2,
  } = findTranslation(props.translation, [
    'email_prompt_1_title',
    'email_prompt_1_sub',
    'email_prompt_1_form',
    'common_ok_button',
    'email_prompt_1_bottom_1',
    'email_prompt_1_bottom_2',
  ]);

  return (
    <div className="save-email-dialog">
      <div className="title">
        {email_prompt_1_title}
      </div>
      <p className="formatted-text">
        {email_prompt_1_sub}
      </p>
      <p>
        {email_prompt_1_form}
      </p>

      <div>
        <div className="error-message">
          Login is temporary unavailable
        </div>
        <input
          id="email"
          type="email"
          className="form-field"
          placeholder={email_prompt_1_form}
          value={email}
          onChange={e => changeEmail(e.target.value)}
        />
        <button
          className="form-button"
          disabled={true}>
          {common_ok_button}
        </button>
      </div>

      <p>
        {email_prompt_1_bottom_1}
      </p>

      <p className="description">
        {email_prompt_1_bottom_2}
      </p>
    </div>
  )
};

const mapStateToProps = (store: AppState) => ({
  translation: store.config.translation
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  openModal: (modalProps: IModalDialog) => dispatch(openModal(modalProps)),
  setEmail: (email: string) => dispatch(setEmail(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInByEmailDialog);
