import React from 'react';
import './App.scss';
import { Switch } from 'react-router';
import LandingPage from './pages/LandingPage/landing-page.component';
import HomePage from './pages/HomePage/home-page.component';
import ModalContainer from './components/Modal/modal-container.component';
import RouteGuard from './components/RouteGuard/route-guard.component';
import SportsOverview from './pages/SportsOverview/sports-overview.component';

const App: React.FC = () => {
  return (
    <>
      <Switch>
        <RouteGuard exact mixed path='/' />
        <RouteGuard exact guest path='/try' component={LandingPage}/>
        <RouteGuard exact private path='/try/home' component={HomePage}/>
        <RouteGuard exact friends path='/try/home/menu/friends' component={HomePage}/>
        <RouteGuard exact friends path='/try/sport-overview/menu/friends' component={SportsOverview}/>
        <RouteGuard exact private path='/try/sport-overview' component={SportsOverview}/>
        <RouteGuard path='*' exact={true} />
      </Switch>
      <ModalContainer/>
    </>
  );
};

export default App;
