import axios from 'axios';
import { CredentialsManager } from './storageManager';


const interceptedAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`
});

interceptedAxios.interceptors.response.use(undefined,
  async (error) => {
    if (error.config && error.response && error.response.status === 401) {

      const { messages, data } = error.response; 
      const isAccesTokenError = messages && messages[0].token_type === 'access'
        && data.code === 'token_not_valid';
        
      if (!isAccesTokenError) {
        throw error;
      }
      
      const token = await refreshToken();
      if (!token) {
        throw error;
      }
      error.config.headers = {
        Authorization: `Bearer ${token}`
      };
      return interceptedAxios.request(error.config);
    }
    throw error;
  }
);

const refreshToken = async () => {
  const { refresh } = CredentialsManager.getCredentials();
  if (!refresh) {
    return;
  }
  const { data: { access } } = await interceptedAxios.post('/token/refresh/', { refresh });
  CredentialsManager.refreshAccessToken(access);
  setAxiosHeader(access);
  return access;
};

export const setAxiosHeader = (access: string) => {
  interceptedAxios.defaults.headers.common = {
    Authorization: `Bearer ${access}`
  };
};

export default interceptedAxios;
