import React from 'react';
import nanoid from 'nanoid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import _ from 'lodash';
import { AppState } from '../../store';
import { ISportMoment } from '../../types/sport-moment';
import { listSportMoments, burstCreateSportMoments } from '../../store/sport-moment/sport-moment.actions';
import './sport-moments.component.scss';
import { whoami } from '../../store/user/user.actions';
import { findTranslation } from '../../utils/findTranslation';
import { ITranslation } from '../../types/config';

const timeLabels = [
  {
    name: '00-06',
    value: 'before_6'
  },
  {
    name: '06-08',
    value: 'between_6_8'
  },
  {
    name: '08-10',
    value: 'between_8_10'
  },
  {
    name: '10-12',
    value: 'between_10_12'
  },
  {
    name: '12-14',
    value: 'between_12_14'
  },
  {
    name: '14-16',
    value: 'between_14_16'
  },
  {
    name: '16-18',
    value: 'between_16_18'
  },
  {
    name: '18-20',
    value: 'between_18_20'
  },
  {
    name: '20-22',
    value: 'between_20_22'
  },
  {
    name: '22-00',
    value: 'after_22'
  }
];

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

interface IPortMomentsProps {
  sportMoments: ISportMoment[];
  translation: ITranslation[];
  whoami: () => void;
  listSportMoments: () => void;
  burstCreateSportMoments: (sportMoments: ISportMoment[]) => void;
}
interface ISportMomentsState {
  sportMoments: ISportMoment[];
}

class SportMoments extends React.Component<IPortMomentsProps, ISportMomentsState> {
  public state: ISportMomentsState = {
    sportMoments: [
      ...this.props.sportMoments
    ]
  }

  componentDidMount() {
    this.props.listSportMoments();
  }

  componentWillReceiveProps(nextProps: IPortMomentsProps) {
    if (nextProps.sportMoments !== this.props.sportMoments) {
      this.setState({
        sportMoments: [
          ...nextProps.sportMoments
        ]
      })
    }
  }

  public renderButtons = () => {
    const rows: JSX.Element[] = [];

    timeLabels.forEach(label => {
      const uid = nanoid(5);
      const cols = [(
        <td key={uid}>
          <span className="side-label">{label.name}</span>
        </td>
      )];

      for (let i = 0; i < 7; i++) {
        const uid = nanoid(5);
        const isSelected: boolean = this.state.sportMoments.some(
          moment => moment.slot === label.value && moment.weekday === weekdays[i]);

        cols.push(
          <td key={uid}>
            <input
              id={uid}
              type="checkbox"
              checked={isSelected}
              onChange={(e) => this.onSportMomentSelected(e, label.value, weekdays[i])}
            />
            <label
              className="day-button"
              htmlFor={uid}>
            </label>
          </td>
        )
      }

      rows.push(
        <tr key={uid}>
          {cols}
        </tr>
      );
    });
    return rows;
  };

  public onSportMomentSelected = (e: React.ChangeEvent<HTMLInputElement>, slot: string, weekday: string) => {
    if(e.currentTarget.checked) {
      this.setState({
        sportMoments: [
          ...this.state.sportMoments,
          { slot, weekday }
        ]
      });
    } else {
      this.setState({
        sportMoments: this.state.sportMoments.filter(
          moment => moment.weekday !== weekday || moment.slot !== slot)
      });
    }
  };

  public onSaveChanges = async () => {
    if (!_.isEqual(this.props.sportMoments, this.state.sportMoments)) {
      await this.props.burstCreateSportMoments(this.state.sportMoments);
      return this.props.whoami();
    }
  }

  render() {
    const { moments_prompt_weekday } = findTranslation(
      this.props.translation, 
      [ 'moments_prompt_weekday' ]
    );
    const weekDays = moments_prompt_weekday.split(', ');

    return (
      <div className="sport-moment">
        <table>
          <tbody>
            <tr className="day-names">
              <th></th>
              { weekDays.map((day, index) =>
                 <th key={index}>{day}</th>
              )}
            </tr>
            {this.renderButtons()}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (store: AppState) => ({
  translation: store.config.translation,
  sportMoments: store.sportMoments.sportMoments
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  whoami: () => dispatch(whoami()),
  listSportMoments: () => dispatch(listSportMoments()),
  burstCreateSportMoments: (sportMoments: ISportMoment[]) => dispatch(burstCreateSportMoments(sportMoments))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(SportMoments)