import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { AppState } from '../../store';
import { Dispatch } from 'redux';
import {IPicture, ISport, SportPreferences} from '../../types/sport';
import Header from '../../components/Header/header.component';
import { PlayedAlreadyIcon } from '../../components/Icon/played-already.component';
import { LikeToTryIcon } from '../../components/Icon/like-to-try.component';
import { NoInterestIcon } from '../../components/Icon/no-interest.component';
import { UndoIcon } from '../../components/Icon/undo.component';
import { updateProfile, addSport } from '../../store/user/user.actions';
import { fetchTranslatedSportList, fethchNextTrySport } from '../../store/sport/sport.actions';
import { fetchFriends } from "../../store/friends/friends.actions";
import { IUser, genderStates, IRelatedSports } from '../../types/user';
import { listUserPrompOrder } from '../../store/config/config.actions';
import { IModalDialog } from '../../store/modal/modal.reducer';
import { closeModal, openModal, openSubModalInv } from '../../store/modal/modal.actions';
import { IUserPrompt, ITranslation } from '../../types/config';
import {
  attachEmailDialog,
  genderSelectionDialog,
  attachBirthYearDialog,
  attachLocationDialog,
  selectSportMomentsDialog,
  onboardingShareDialog,
  buildCarouselDialog,
  buildSportLinkedDialog,
  goToAppDialog,
  friendsDialog,
  identityDialog,
  nicknameDialog,
  avatarDialog,
  welcomeDialog,
  splitPromptDialog,
  emailOnboardingDialog,
  passwordDialog,
  weeklyHabitDialog,
  weeklyTargetDialog,
  emailOnboardingTrainersDialog, profileDialog,
} from '../../components/Modal/dialogs';
import './home-page.component.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { findTranslation } from '../../utils/findTranslation';
import { ImageViewer } from '../../components/ImageViewer/image-viewer.component';
import { SportRepo } from '../../store/sport/sport.repository';

const dialogs: { [key: string]: IModalDialog | Function } = {
  friends: friendsDialog,
  split_share_prompt: onboardingShareDialog,
  email: attachEmailDialog,
  location: attachLocationDialog,
  moments: selectSportMomentsDialog,
  gender: genderSelectionDialog,
  birthyear: attachBirthYearDialog,
  gotoapp_prompt: goToAppDialog,
  identity_prompt: identityDialog,
  name_prompt: nicknameDialog,
  avatar_prompt: avatarDialog,
  welcome_prompt: welcomeDialog,
  email_onboarding_prompt: emailOnboardingDialog,
  password_prompt: passwordDialog,
  weekly_habit_prompt: weeklyHabitDialog,
  weekly_target_prompt: weeklyTargetDialog,
  email_onboarding_trainer_prompt: emailOnboardingTrainersDialog
};

interface IHomePageProps extends RouteComponentProps {
  me: IUser;
  trySport: ISport;
  sportList: ISport[];
  userPrompt: IUserPrompt[];
  friendList: IUser[];
  translation: ITranslation[];
  listUserPromptOrder: (language: string) => void;
  addSport: (sportId: number, pref: SportPreferences) => void;
  openModal: (modalProps: IModalDialog) => void;
  closeModal: () => void;
  openSubModalInv: (modalProps: IModalDialog) => void;
  fetchNextTrySport: (language: string) => void;
  fetchTranslatedSportList: (language: string) => void;
  fetchFriends: () => void;
  updateProfile: (profile: Partial<IUser>) => void;
  modalDialogs: IModalDialog[];
}

interface IHomePageState {
  displayedSport?: ISport;
  action: 'undo' | 'overview' | 'vote',
  pressed: string
}

class HomePage extends React.Component<IHomePageProps, IHomePageState> {
  public state: IHomePageState = {
    displayedSport: this.props.trySport,
    action: 'vote',
    pressed: ''
  };

  public counter = 0;

  async componentDidMount() {
    /*await this.props.updateProfile({
      prompt: 12
    })*/

    if (!this.props.userPrompt.length) {
      await this.props.listUserPromptOrder(this.props.me.language);
      if (!this.props.modalDialogs.length) {
        this.showDialog();
      }
    }

    if (!this.props.sportList.length) {
      this.props.fetchTranslatedSportList(this.props.me.language);
    }

    if (this.props.sportList.length && this.props.location.state) {
      const { displayedSport } = this.props.location.state;
      this.setState({
        displayedSport: this.props.sportList.find(({ id }) => displayedSport.id === id),
        action: 'overview'
      });
    } else if (!this.props.trySport) {
      this.props.fetchNextTrySport(this.props.me.language);
    }
  }

  componentDidUpdate (prev: any) {
    if (!this.props.userPrompt.length) {
      return;
    }

    const direct = prev.modalDialogs.length && prev.modalDialogs[0].inSequence && !this.props.modalDialogs.length;
    if (prev.me.prompt !== this.props.me.prompt || direct) {
      this.showDialog();
    }
  }

  componentWillReceiveProps(nextProps: IHomePageProps) {
    if (nextProps.trySport !== this.props.trySport) {
      this.setState({
        displayedSport: nextProps.trySport
      });
    }
  }
  
  onUndoClick = async () => {
    this.setState({ pressed: 'undo' });
    const { sports } = this.props.me;
    const lastAddedSport = sports[sports.length - 1];
    SportRepo.findSportById(lastAddedSport.sport)
        .then((lastSport) => { // fix for Safari
            this.setState({
                displayedSport: lastSport,
                action: 'undo',
                pressed: ''
            })
        });
  };

  onPreferenceChanged = async (pref: SportPreferences) => {
    this.setState({ pressed: pref });
    const { displayedSport } = this.state;

    await this.props.addSport(displayedSport!.id!, pref);
    this.counter++;

    this.showLinkedFriends(pref);
    this.showDialog();
    this.setState({
      action: 'vote',
      pressed: ''
    });

    this.props.fetchNextTrySport(this.props.me.language);
  };

  private isDialogPass = (dialogName: string) => {
    const {
      gender,
      friends,
      sport_moments,
      username,
      country,
      postal_code,
      picture,
      email
    } = this.props.me;

    switch(dialogName) {
      case 'gender':
        return !!gender;
      case 'friends':
        return !!friends.length;
      case 'moments':
        return !!sport_moments.length;
      case 'email':
        return !!email;
      case 'location':
        return !!country && !!postal_code;
      case 'name_prompt_onboarding':
        return !!username;
      case 'avatar_prompt_onboarding':
        return !!picture;
      default:
        return false;
    }
  };

  private currentDialog = () => this.props.userPrompt.find(({ order, prompt }) => {
    const next = this.props.me.prompt === 0 ? 1 : this.props.me.prompt;
    return order === next;
  })!;

  private getDialogInstance = (dialog: string): IModalDialog | Function => {
    if (dialog.includes('progress_prompt') || dialog.includes('stop_prompt') || dialog.includes('end_prompt')) {
      return buildCarouselDialog;
    }

    const splitted = dialog.includes('split_prompt') ||
        dialog.includes('split_withlink');
    if (splitted) {
      return splitPromptDialog(this.currentDialog());
    }

    return dialogs[dialog];
  };

  private combinedDialogs = (dialog: string): boolean => {
    const separated = [
      'progress_prompt',
      'stop_prompt',
      'end_prompt',
      'split_prompt',
      'split_withlink'
    ]

    const found = separated.find(item => dialog.includes(item))
    return Boolean(found)
  };

  private nextModal() {
    if (!this.currentDialog()) {
      return false;
    }

    const dialog = this.currentDialog()!.prompt;
    if (dialog === null) {
      return false;
    }

    if (!dialogs[dialog] && !this.combinedDialogs(dialog)) {
      return false;
    }

    return !this.isDialogPass(dialog);
  }

  private showDialog = async () => {
    if (this.props.me.prompt >= this.props.userPrompt.length) {
      return;
    }

    if (!this.nextModal()) {
      return this.nextDialog();
    }

    if (this.counter < this.currentDialog()!.space) {
      return;
    }

    const dialog = this.currentDialog()!.prompt;
    const modal: IModalDialog | Function = this.getDialogInstance(dialog);
    await this.props.closeModal();
    if (typeof modal === 'function') {
      this.props.openSubModalInv(modal(this.currentDialog()));
    } else {
      this.props.openSubModalInv(modal);
    }

    this.counter = 0;
  };

  private nextDialog = () => {
    this.counter = 0;
    this.props.updateProfile({
      prompt: Number(this.props.me.prompt) + 1
    });
    this.props.closeModal();
  };

  private showLinkedFriends = async (pref: SportPreferences) => {
    if (!this.props.me.friends.length || pref === 'not_interested') {
      return;
    }

    const { displayedSport } = this.state;
    const isMutualInterest = (sport: IRelatedSports) => (sport.sport === displayedSport!.id! && sport.result === pref);
    const linkedFriends = this.props.friendList.filter(friend => friend.sports.some(isMutualInterest));

    if (linkedFriends.length) {
      const linkedDialog = buildSportLinkedDialog(linkedFriends, displayedSport!, pref);
      this.props.openModal(linkedDialog);
    }
  };

  render() {
    const { displayedSport, action } = this.state;
    const votedSport = this.props.me.sports.find(({ sport }) => sport === (displayedSport && displayedSport.id));

    const {
      main_page_wikipedia,
      main_page_undo,
      main_page_no_interest,
      main_page_like_to_try,
      main_page_played_already
    } = findTranslation(this.props.translation, [
      'main_page_wikipedia',
      'main_page_undo',
      'main_page_no_interest',
      'main_page_like_to_try',
      'main_page_played_already'
    ]);

    const isUndoDisabled = (!!votedSport && action !== 'vote') || !this.props.me.sports.length;
    const isButtonSelected = (preferenceName: SportPreferences) => {
      if (votedSport && votedSport.result === preferenceName) {
        return `selected-${votedSport!.result}`;
      }
      if (this.state.pressed && this.state.pressed === preferenceName) {
        return `selected-${this.state.pressed}`;
      }
    };

    const actionButtons = [
      {
        id: 'undo',
        handler: this.onUndoClick,
        icon: <UndoIcon />,
        description: main_page_undo,
        btnClass: `fab-button-min ${isButtonSelected('undo')}`,
        disabled: isUndoDisabled
      },
      {
        id: 'not_interested',
        handler: () => this.onPreferenceChanged('not_interested'),
        icon: <NoInterestIcon />,
        description: main_page_no_interest,
        btnClass: `fab-button ${isButtonSelected('not_interested')}`
      },
      {
        id: 'like_to_try',
        handler: () => this.onPreferenceChanged('like_to_try'),
        icon: <LikeToTryIcon />,
        description: main_page_like_to_try,
        btnClass: `fab-button ${isButtonSelected('like_to_try')}`
      },
      {
        id: 'already_played',
        handler: () => this.onPreferenceChanged('already_played'),
        icon: <PlayedAlreadyIcon />,
        description: main_page_played_already,
        btnClass: `fab-button-min ${isButtonSelected('already_played')}`
      }
    ];

    return (
      <>
        <Header isHomePage={true}/>
        { displayedSport ? (
          <div className="main-page">
            <div className="favorite-poll">
              <ImageViewer name={displayedSport.name} images={displayedSport.images}/>

              <a className="wiki-link" href={displayedSport.url} target="blank">
                <span className="wiki-logo">W</span>
                <span>{main_page_wikipedia}</span>
              </a>

              <div className="control-buttons">
                {
                  actionButtons.map(button => (
                    <button
                      className="action"
                      disabled={button.disabled}
                      key={button.description}
                      onClick={button.handler}>
                      <div className={button.btnClass}>
                        { button.icon }
                      </div>
                      {button.description}
                    </button>
                  ))
                }
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* todo loader */}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (store: AppState) => ({
  me: store.user.me!,
  sportList: store.sport.sportList.sort((sport1: ISport, sport2: ISport) => {
    const name1 = _.toLower(sport1.name);
    const name2 = _.toLower(sport2.name);
    return (name1 < name2) ? -1 : 1;
  }),
  userPrompt: store.config.userPrompt
      .sort((p1: IUserPrompt, p2: IUserPrompt) => ((p1.order < p2.order) ? -1 : 1)),
  friendList: store.friends.friendList,
  translation: store.config.translation,
  trySport: store.sport.trySport!,
  modalDialogs: store.modal!.modalDialogs
});

const mapStateToDispatch = (dispatch: Dispatch) => ({
  listUserPromptOrder: (language: string) => dispatch(listUserPrompOrder(language)),
  addSport: (sportId: number, pref: SportPreferences) => dispatch(addSport(sportId, pref)),
  openModal: (modalProps: IModalDialog) => dispatch(openModal(modalProps)),
  openSubModalInv: (modalProps: IModalDialog) => dispatch(openSubModalInv(modalProps)),
  fetchTranslatedSportList: (language: string) => dispatch(fetchTranslatedSportList(language)),
  fetchNextTrySport: (language: string) => dispatch(fethchNextTrySport(language)),
  fetchFriends: () => dispatch(fetchFriends()),
  closeModal: () => dispatch(closeModal()),
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile))
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(withRouter(HomePage));
