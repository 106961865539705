import { SportPreferences } from "./sport";

export interface IUser extends ICredentials, ILocation, IUserFriend, IUserRequestParams {
  id: number;
  code: string;
  email: string;
  password: string;
  username: string;
  first_name: string;
  last_name: string;
  picture: string;
  gender: Gender;
  birthday: string;
  birthyear: number;
  company: string;
  department: string;
  friends: [];
  sport_moments: [];
  sports: IRelatedSports[];
  language: string;
  work_location: string;
  friend: IUserFriend;
  invited_by: null | IUserFriend;
  prompt: number | string;
  default_password: boolean;
  email_verified: boolean;
  weekly_habit: number;
  role: string;
  weekly_target: number;
  source_account: boolean;
  [key: string]: number | string | [] | IRelatedSports[] | IUserFriend | null | boolean;
}

export interface IUserFriend {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  picture: string;
}

export interface ICredentials {
  access: string;
  refresh: string;
}

export interface ILocation {
  postal_code: string | number;
  country: string;
  location: string;
}

export interface IRelatedSports {
  created: string;
  result: SportPreferences;
  sport: number;
}

export interface IUserCreatePayload {
  language: string,
  email?: string | undefined,
  invited_by?: string | undefined
}

export type Gender = 'male' | 'female' | 'prefer_not_to_say';

export const genderStates = {
  MALE: 'male',
  FEMALE: 'female',
  HIDDEN: 'prefer_not_to_say'
};

export interface IUserRequestParams {
  emailType: string
}
