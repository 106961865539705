import React, { createRef, forwardRef, Ref, RefObject } from 'react';
import SportMoments from '../../../SportMoments/sport-moments.component';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { Dispatch } from 'redux';
import { openModal } from '../../../../store/modal/modal.actions';
import { IModalDialog } from '../../../../store/modal/modal.reducer';
import './select-sport-moments-dialog.component.scss';
import { sportMomentSavedConfitmation } from '../../dialogs';
import { ITranslation } from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';

interface ISelectedSportMomentsDialogProps {
  translation: ITranslation[];
  openModal: (payload: IModalDialog) => void;
}
const SelectSportMomentsDialog: React.FC<ISelectedSportMomentsDialogProps> = (props: ISelectedSportMomentsDialogProps) => {
  const selectMomentRef: RefObject<any> = createRef();
  const SportMomentsWithRef = forwardRef(
    (props: any, ref: Ref<any>) => <SportMoments ref={ref}/>
  );
  const onSaveSportMoments = async () => {
    await selectMomentRef.current.onSaveChanges();
    props.openModal(sportMomentSavedConfitmation);
  }

  const {
    moments_prompt_title,
    moments_prompt_sub,
    common_ok_button
  } = findTranslation(props.translation, [
    'moments_prompt_title',
    'moments_prompt_sub',
    'common_ok_button'
  ]);

  return (
    <div className="select-sport-moments-dialog">
      <p className="dialog-description">
        {moments_prompt_title}
      </p>
      <p className="description">
        {moments_prompt_sub}
      </p>
      <div className="sport-moments">
        <SportMomentsWithRef ref={selectMomentRef}/>
      </div>
      <button 
        className="form-button"
        onClick={onSaveSportMoments}
      >
        {common_ok_button}
      </button>
    </div>
  )
};

const mapStateToProps = (store: AppState) => ({
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openModal: (payload: IModalDialog) => dispatch(openModal(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectSportMomentsDialog);