import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { closeModal } from '../../store/modal/modal.actions';
import './modal-container.component.scss';
import logo from "../../assets/img/logo_blauw.png";
import donnaAvatar from "../../assets/img/donna.jpg";

interface IModalDialogProps {
  onClose: () => void;
  children?: React.ReactNode;
  withCloseBtn?: boolean;
  dialogClass?: string;
  wrapperClass?: string;
  bgClass?: string;
  pictogram?: string;
  permanent?: boolean;
  onboarding?: string;
  logo?: boolean;
  pictogramWrapperClass?: string;
}
const ModalContainer: React.FC<IModalDialogProps> = (props: IModalDialogProps) => {
  const { dialogClass = '' } = props;
  return (
    <>
      { props.children && (
        <div className={`background ${props.bgClass}`} onClick={!props.permanent ? props.onClose : () => {}}>
          { props.logo && <img src={logo} className="prompt-logo" /> }
          <div className={`${props.wrapperClass || 'dialog-wrapper'}`}>
            <div
              className={`${dialogClass || 'default-dialog'} ${!!props.pictogram && 'with-pictogram'} dialog-content`}
              onClick={(e) => e.stopPropagation()}>
              <div className="wrapper">
                { (props.pictogram || props.withCloseBtn) &&
                  <div className="modal-header">
                    <div className={`${props.pictogramWrapperClass || ''}`}>
                      { props.pictogram && (
                        <img className="pictogram" src={props.pictogram} alt="pictogram"/>
                      )}
                    </div>
                    { props.withCloseBtn && (
                      <span className="close-btn" onClick={props.onClose} >X</span>
                    )}
                  </div> }
                { props.onboarding &&
                  <div className="friend-avatar__container">
                    <div className="friend-avatar"
                         style={{ backgroundImage: `url(${props.onboarding})` }} >
                    </div>
                  </div>
                }
                { props.children }
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
};

const mapStateToProps = (store: AppState) => {
  const { modalDialogs } = store.modal;
  const lastDialog = modalDialogs[modalDialogs.length - 1];

  if (lastDialog && lastDialog.style) {
    let { withCloseBtn, dialogClass, wrapperClass, pictogram, bgClass, permanent, onboarding, logo, pictogramWrapperClass } = lastDialog.style!;

    return {
      children: lastDialog.modalComponent,
      withCloseBtn,
      dialogClass,
      bgClass,
      wrapperClass,
      pictogram,
      permanent,
      logo,
      pictogramWrapperClass,
      onboarding: onboarding ?
          ((store.user.me!.invited_by !== null && store.user.me!.invited_by.picture) || donnaAvatar) :
          ''
    }
  }
  return {
    children: lastDialog && lastDialog.modalComponent
  }
};

const mapStateToDispatch = (dispatch: Dispatch) => ({
  onClose: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ModalContainer);
