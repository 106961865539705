import { SPORT_LIST_TRANSLATED, SPORT_NEXT_TRY_SPORT } from '../action-types';
import { ISport } from '../../types/sport';
import { Action } from 'redux';

interface ISportState {
  sportList: ISport[];
  trySport?: ISport;
}
interface ISportAction extends Action {
  payload: ISportState;
}

const initState: ISportState = {
  sportList: []
}

export const sportReducer = (state = initState, action: ISportAction): ISportState => {
  switch(action.type) {
    case SPORT_LIST_TRANSLATED: 
      return { 
        ...state,
        sportList: [ ...action.payload.sportList ]
      }
    case SPORT_NEXT_TRY_SPORT:
      return { 
        ...state,
        trySport: action.payload.trySport
      }
    default:
      return state;
  }
}