import React, {useState, useEffect} from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import './login-dialog.component.scss';
import {setEmail, setIdentity, updateProfile, whoami} from '../../../store/user/user.actions';
import emailValidator from 'email-validator';
import { identityDialog, mailSendConfirmation } from '../dialogs';
import { IUser } from '../../../types/user';
import { IModalDialog } from '../../../store/modal/modal.reducer';
import { openSubModal } from '../../../store/modal/modal.actions';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { IUserPrompt } from '../../../types/config';
import {addFriend} from "../../../store/friends/friends.actions";

interface IPlaceholder {
  email: string;
  password: string;
  button: string;
}

interface LoginDialogProps {
  openSubModal: (modal: IModalDialog) => void;
  whoami: () => void;
  setEmail: (email: string, password: string, confirm: boolean, confirmCb: () => void, errCb: () => void) => ({access: string, refresh: string} | undefined);
  dialog: IUserPrompt;
  me: IUser;
  addFriend: (friendCode: string) => IUser;
  updateProfile: (profile: Partial<IUser>) => void;
  placeholders: IPlaceholder
}

const LoginDialog: React.FC<LoginDialogProps> = (props: LoginDialogProps) => {
  const [ email, changeEmail ] = useState('');
  const [ password, changePassword ] = useState('');
  const [ visibility, changeVisibility ] = useState(false);

  const validate = () => {
    const exists = (email.trim().length > 2) && (password.trim().length > 2);
    return exists && emailValidator.validate(email);
  };

  const cb = async () => {
    props.openSubModal(identityDialog(props.dialog));
  }

  const click = async () => {
    const registered = !props.me.default_password && props.me.email_verified;
    const credentials = await props.setEmail(email, password, !registered, cb, cb);
    if (credentials) {
        await props.whoami();
        props.me.friends.forEach((friend: Partial<IUser>) => {
            props.addFriend(friend.username!);
        });
    }
    if (registered) {
        await props.updateProfile({ prompt: Number(props.me.prompt) + 1 });
    }
  };

  return (
      <div>
          <input
              id="email"
              type="email"
              className="form-field"
              placeholder={props.placeholders.email}
              value={email}
              onChange={e => changeEmail(e.target.value)} />
          <div className="position-relative">
              <input
                  id="password"
                  type={ !visibility ? 'password' : 'text' }
                  className="form-field"
                  placeholder={props.placeholders.password || 'Enter password'}
                  value={password}
                  onChange={e => changePassword(e.target.value)} />
              <div className="password-visibility"
                   onClick={() => changeVisibility(!visibility)}>
                  { !visibility ? <FaEye /> : <FaEyeSlash /> }
              </div>
          </div>
          <button
              className="form-button"
              disabled={!validate()}
              onClick={() => click()}>
              {props.placeholders.button}
          </button>
      </div>
  );
};

const mapStateToProps = (store: AppState) => ({
  me: store.user.me!
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openSubModal: (modalProps: IModalDialog) => dispatch(openSubModal(modalProps)),
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
  whoami: () => dispatch(whoami()),
  addFriend: (friendCode: string) => dispatch(addFriend(friendCode, false)),
  setEmail: (email: string, password: string, confirm: boolean, confirmCb: () => void, errCb: () => void) => dispatch(setIdentity(email, password, confirm, confirmCb, errCb))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginDialog);
