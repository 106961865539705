import { IUser, IRelatedSports } from './../../types/user';
import { USER_CREATED, WHOAMI, USER_UPDATED, AVATAR_UPDATED, SPORT_ADDED } from './../action-types';
import { Action } from 'redux';

export interface IUserState {
  me?: IUser;
}
export interface IUserAction extends Action {
  payload: {
    sport?: IRelatedSports,
    me?: IUser;
  };
}

const initState: IUserState = {
  me: undefined
};

export const userReducer = (state = initState, action: IUserAction): IUserState => {
  switch(action.type) {
    case USER_CREATED:
      return { ...action.payload };
    case WHOAMI:
      return { ...action.payload };
    case USER_UPDATED:
      return { ...action.payload };
    case AVATAR_UPDATED:
      return { ...action.payload };
    case SPORT_ADDED: {
      const newSport = action.payload.sport!;
      const me = { ...state.me! };

      const sports = me.sports.map(sport => {
        if (sport.sport === +newSport.sport) {
          return {
            sport: newSport.sport,
            result: newSport.result,
            created: newSport.created
          };
        }
        return sport;
      });
      if (!sports.some(({ sport }) => sport! === +newSport.sport)) {
        sports.push({
          sport: +newSport.sport,
          result: newSport.result,
          created: newSport.created
        });
      }

      return {
        me: {
          ...me, sports,
        }
      };
    }
    default:
      return state;
  }
};