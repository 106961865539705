import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import iconSubmit from '../../../assets/img/icn_submit.svg';
import { AppState } from '../../../store';
import { fetchFriends, addFriend } from '../../../store/friends/friends.actions';
import { IUser } from '../../../types/user';
import FriendList from '../../FriendList/friend-list.component';
import { IModalDialog } from '../../../store/modal/modal.reducer';
import { openModal } from '../../../store/modal/modal.actions';
import './friends-dialog.component.scss';
import { ITranslation } from '../../../types/config';
import { findTranslation } from '../../../utils/findTranslation';
import {ISport} from "../../../types/sport";

import 'loaders.css/src/animations/ball-spin-fade-loader.scss';
import Loader from 'react-loaders';

interface IFriendsDialogProps {
  me: IUser;
  friendList: IUser[];
  translation: ITranslation[];
  sportList: ISport[];
  openModal: (modalProps: IModalDialog) => void;
  fetchFriends: () => void;
  addFriend: (friendCode: string) => IUser;
}
interface IFriendsDialogState {
  friendCode: string;
  loaded: boolean;
}

class FriendsDialog extends React.Component<IFriendsDialogProps, IFriendsDialogState> {
  public state: IFriendsDialogState = {
    friendCode: '',
    loaded: false
  };

  async componentDidMount() {
    this.setState({ loaded: false });
    await this.props.fetchFriends();
    this.setState({ loaded: true });
  }

  public onFriendCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ friendCode: e.target.value });
  };

  public addFriend = () => {
    this.props.addFriend(this.state.friendCode);
  };

  public onSave = () => {
    const { share_social } = findTranslation(this.props.translation, ['share_social']);
    const shareMessage = share_social
        .replace(/https:\/\/[^\s|]+/, `$&?user=${this.props.me.username}&language=${this.props.me.language}`)
        .replace('<code>', this.props.me.username);

    const navigator: any = window.navigator;
    if (navigator && navigator.share) {
      navigator.share({
        title: 'Share',
        text: shareMessage,
      });
    }
  };

  render() {
    const {
      friends_prompt_title,
      friends_prompt_sub,
      share_button
    } = findTranslation(this.props.translation, [
      'friends_prompt_title',
      'friends_prompt_sub',
      'share_button'
    ]);

    return (
      <div className="friends-dialog">
        <p className="title">
          {friends_prompt_title}
        </p>
        <p className="dialog-description">
          {friends_prompt_sub}
        </p>

        {
          this.state.loaded ?
            <>
              <div className='separator' />
              <FriendList />
            </> :
            <div className='loader-container'>
              <Loader
                  type='ball-spin-fade-loader'
                  active
                  innerClassName='loader-item--orange'
              />
            </div>
        }
      </div>
    )
  };
}

const mapStateToProps = (store: AppState) => ({
  me: store.user.me!,
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openModal: (modalProps: IModalDialog) => dispatch(openModal(modalProps)),
  addFriend: (friendCode: string) => dispatch(addFriend(friendCode)),
  fetchFriends: () => dispatch(fetchFriends()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendsDialog);
