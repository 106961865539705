import { ITranslation } from "../types/config";

interface IDynamic {
  [key: string]: string;
}

export const findTranslation = (translation: ITranslation[], targetParams: string[]) => {
  const result: IDynamic = {};

  targetParams.forEach(targetParam => {
    const res = translation.find(({ locator }) => locator === targetParam)!;
    if (res) {
      result[targetParam] = res.value;
    }
  })

  return result;
}