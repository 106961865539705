import React, {useRef, useState} from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { closeModal } from '../../../store/modal/modal.actions';
import { ITranslation, IUserPrompt } from '../../../types/config';
import { findTranslation } from '../../../utils/findTranslation';
import { updateAvatar, updateProfile } from '../../../store/user/user.actions';
import { IUser } from '../../../types/user';

import donnaAvatar from '../../../assets/img/donna.jpg';

import './avatar-dialog.component.scss';
import Loader from 'react-loaders';
import { IModalDialog } from "../../../store/modal/modal.reducer";
import { ImageEditor } from '../../ImageEditor/ImageEditor';

import { Drawer } from '../../ImageEditor/Drawer'
import loadImage from "blueimp-load-image";

interface AvatarDialogProps {
    translation: ITranslation[];
    closeModal: () => void;
    dialog: IUserPrompt;
    updateAvatar: (avatar: FormData) => void;
    updateProfile: (profile: Partial<IUser>) => void;
    modal: IModalDialog | undefined;
    me: IUser;
}

const AvatarDialog: React.FC<AvatarDialogProps> = (props: AvatarDialogProps) => {
    const friend = (props.me.invited_by !== null && props.me.invited_by.picture) || donnaAvatar;
    const editor = useRef(null)
    const [ image, changeImage ] = useState<string>('');
    const [ file, changeFile ] = useState<File | null>(null);
    const [ processing, changeProcessing ] = useState(false);
    const {
        avatar_prompt_button_1,
        avatar_prompt_button_2,
        avatar_prompt_sub,
        avatar_prompt_title
    } = findTranslation(props.translation, [
        'avatar_prompt_button_1',
        'avatar_prompt_button_2',
        'avatar_prompt_sub',
        'avatar_prompt_title'
    ]);

    const name =
        (props.me.first_name !== null ? props.me.first_name : '') +
        (props.me.last_name !== null ? ` ${props.me.last_name}` : '');
    const promptTitle = avatar_prompt_title
        .replace('<name>', name);

    const sendData = async (file: File) => {
        changeProcessing(true);
        const avatarPayload = new FormData();
        avatarPayload.append('email', props.me.email!);
        avatarPayload.append('picture', file);
        await props.updateAvatar(avatarPayload);
        await props.updateProfile({ prompt: props.dialog.order + 1 });
    }

    const submit = async () => {
        const {croppedAreaPixels, rotation} = (editor.current as any).state
        const drawer = Drawer(sendData, file)
        await drawer(image, croppedAreaPixels, rotation)
    };

    const readFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const loaded = e.target.files![0];
        const reader = new FileReader();
        reader.onload = (e) => {
            changeImage(reader.result! as string);
            changeFile(loaded as File);
        };

        e.target.value = ''
        if (!loaded) {
            resetEditor()
        } else {
            const loadImageOptions: any = { canvas: true }
            loadImage.parseMetaData(loaded, (data: any) => {
                if (data.exif && data.exif!.get('Orientation')) {
                    loadImageOptions.orientation = data.exif.get('Orientation')
                }
                loadImage(loaded, (canvas) => {
                    const url = (canvas as HTMLCanvasElement).toDataURL(loaded.type) as string
                    changeImage(url)
                    changeFile(loaded as File)
                }, loadImageOptions)
            })
        }
    };

    const resetEditor = () => {
        changeImage('')
        changeFile(null);
    }

    return (
        <div className="avatar-dialog">
            { image &&
                <div className="position-relative">
                    <ImageEditor image={image} ref={editor} />
                    <div className={'cropper__rm-btn'} onClick={resetEditor}>
                        remove
                    </div>
                </div>
            }
            { processing ?
                <div className='loader-container--transparent'>
                    <Loader
                        type='ball-spin-fade-loader'
                        active
                        innerClassName='loader-item--orange' />
                </div> : ''
            }
            { !image &&
                <div className="friend-avatar__container">
                    <div className="friend-avatar"
                         style={{backgroundImage: `url(${friend})`}}>
                    </div>
                </div>
            }
            <div className="position-relative">
                <div className="formatted-text">
                    "{promptTitle}
                </div>
                <p className="formatted-text">
                    {avatar_prompt_sub}"
                </p>
                <div>
                    <div className="choose-file">
                        <div className="form-button form-button--rect form-button--orange">{avatar_prompt_button_1}</div>
                        <input type="file" accept="video/*|image/*" onChange={readFile} disabled={processing}/>
                    </div>
                    <button
                        className="form-button form-button--rect"
                        disabled={!image || processing}
                        onClick={() => submit()}>
                        {avatar_prompt_button_2}
                    </button>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (store: AppState) => ({
    me: store.user.me!,
    modal: store.modal!.modalDialogs.slice(-1).pop(),
    translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    updateAvatar: (avatar: FormData) => dispatch(updateAvatar(avatar)),
    updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AvatarDialog);
