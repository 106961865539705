import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import './identity-dialog.component.scss';
import { findTranslation } from '../../../../utils/findTranslation';
import { updateProfile } from '../../../../store/user/user.actions';
import LoginDialog from '../../LoginDialog/login-dialog.component';
import { IUser } from '../../../../types/user';
import {ITranslation, IUserPrompt} from "../../../../types/config";

interface IdentityDialogProps {
  dialog: IUserPrompt;
  me: IUser;
  translation: ITranslation[];
  updateProfile: (profile: Partial<IUser>) => void;
}

const IdentityDialog: React.FC<IdentityDialogProps> = (props: IdentityDialogProps) => {
  const {
    identity_prompt_button_1,
    identity_prompt_button_2,
    identity_prompt_placeholder,
    identity_prompt_sub,
    identity_prompt_title,
    password_prompt_title
  } = findTranslation(props.translation, [
    'identity_prompt_button_1',
    'identity_prompt_button_2',
    'identity_prompt_placeholder',
    'identity_prompt_sub',
    'identity_prompt_title',
    'password_prompt_title'
  ]);

  const placeholders = {
    email: identity_prompt_placeholder,
    password: password_prompt_title,
    button: identity_prompt_button_1
  }

  const click = async (known: boolean = true) => {
    await props.updateProfile({prompt: props.dialog.option_1_order});
    return;
  }

  return (
    <div className="save-email-dialog">
      <div className="filler bg--orange" />
      <div className="filler bg--grey" />
      <div className="people-flat-icon--5" />
      <div className="formatted-text mt-4">
        {identity_prompt_title}
      </div>
      <p className="formatted-text">
        {identity_prompt_sub}
      </p>
      <LoginDialog
        placeholders={placeholders}
        dialog={props.dialog} />
      <button className="form-button mt-4 form-button--link mw-180"
              onClick={() => click(false)}>
        {identity_prompt_button_2}
      </button>
    </div>
  );
};

const mapStateToProps = (store: AppState) => ({
  translation: store.config.translation,
  userPrompt: store.config.userPrompt,
  me: store.user.me!
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentityDialog);
