import { ICredentials } from '../types/user';

const ACCESS_TOKEN = 'access';
const REFRESH_TOKEN = 'refresh';

const LANGUAGE = 'language';
const SPORT = 'sport';

export class CredentialsManager {
  public static refreshAccessToken(access: string) {
    localStorage.setItem(ACCESS_TOKEN, access);
  };
  
  public static saveCredentials(credentials: ICredentials) {
    localStorage.setItem(ACCESS_TOKEN, credentials.access);
    localStorage.setItem(REFRESH_TOKEN, credentials.refresh);
  };
  
  public static getCredentials() {
    const access = localStorage.getItem(ACCESS_TOKEN);
    const refresh = localStorage.getItem(REFRESH_TOKEN);
    return {
      refresh,
      access
    }
  };
}

export class Localization {
  public static setLanguage(language: string) {
    localStorage.setItem(LANGUAGE, language)
  }
  
  public static getLanguage() {
    return localStorage.getItem(LANGUAGE);
  }
}
