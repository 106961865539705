import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import _ from 'lodash';
import { ILocation, IUser} from '../../../../types/user';
import { updateProfile } from '../../../../store/user/user.actions';
import {closeModal, openModal} from '../../../../store/modal/modal.actions';
import { IModalDialog } from '../../../../store/modal/modal.reducer';
import './attach-location-dialog.component.scss';
import { AppState } from '../../../../store';
import {ICountries, ITranslation, IUserPrompt} from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import {fetchCountries} from "../../../../store/config/config.actions";
import {FormDropdown, ISelectOption} from "../../../FormDropdown/form-dropdown.component";
import {ValueType} from "react-select/src/types";

interface IAttachLocationDialogProps {
  me: IUser;
  profile?: Partial<IUser>;
  translation: ITranslation[];
  updateLocation?: (location: Partial<ILocation>) => void;
  fetchCountries: () => void;
  openModal: (modalProps: IModalDialog) => void;
  countries: ICountries;
  onClose: () => void;
  dialog?: IUserPrompt;
  updateProfile: (profile: Partial<IUser>) => void;
}

class AttachLocationDialog extends React.Component<IAttachLocationDialogProps> {
  public state = {
    profile: this.props.me
  };

  componentDidMount() {
    if (!this.props.countries.length) {
      this.props.fetchCountries();
    }
    if (!this.props.me.country) {
      this.setState({
        profile: {
          ...this.state.profile,
          country: 'NL'
        }
      })
    }
  }

  private validateLocation = () => {
    return this.state.profile.postal_code && this.state.profile.country;
  };

  public onCountryChanged = (option: ValueType<ISelectOption>) => {
    const value = (option as ISelectOption).value;
    this.setState({
      profile: {
        ...this.state.profile,
        country: value
      }
    })
  };

  public onChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    this.setState({
      profile: {
        ...this.state.profile,
        postal_code: e.currentTarget.value
      }
    });
  };

  public saveChanges = () => {
    this.props.updateProfile({
      postal_code: this.state.profile['postal_code'],
      country: this.state.profile['country'],
      prompt: this.props.dialog!.order + 1
    });
  };

  render() {
    const { postal_code, country } = this.state.profile;

    const countryOptions: ISelectOption[] = [];
    _.each(this.props.countries, (value, key) => {
      countryOptions.push({
        label: _.startCase(value),
        value: key
      });
    });

    const {
      location_prompt_title,
      common_ok_button,
      location_prompt_placeholder
    } = findTranslation(
      this.props.translation,
      [
        'location_prompt_placeholder',
        'location_prompt_title',
        'common_ok_button'
      ]
    );
    
    return (
      <div className="attach-location">
        <p className="dialog-description">
          {location_prompt_title}
        </p>
        <input
            className="form-field"
            placeholder={location_prompt_placeholder}
            onChange={(e) => this.onChange(e, 'postal_code')}
            value={postal_code || ''}
        />
        <FormDropdown
            placeholder="Select your country"
            value={countryOptions.find(({ value }) => value === country)}
            onChange={this.onCountryChanged}
            options={countryOptions} />
        <button className="form-button"
                disabled={!this.validateLocation()}
                onClick={this.saveChanges}>
          {common_ok_button}
        </button>
      </div>
    )
  }
}

const mapStateToProps = (store: AppState) => ({
  me: store.user.me!,
  translation: store.config.translation,
  countries: store.config.countries
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
  openModal: (modalProps: IModalDialog) => dispatch(openModal(modalProps)),
  onClose: () => dispatch(closeModal()),
  fetchCountries: () => dispatch(fetchCountries()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttachLocationDialog);
