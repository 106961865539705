import React, {useState} from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { closeModal } from '../../../../store/modal/modal.actions';
import './nickname-dialog.component.scss';
import {ITranslation, IUserPrompt} from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import { updateProfile } from '../../../../store/user/user.actions';
import { IUser } from '../../../../types/user';

interface IdentityDialogProps {
    translation: ITranslation[];
    closeModal: () => void;
    updateProfile: (profile: Partial<IUser>) => void;
    dialog: IUserPrompt;
    me: IUser,
}

const NicknameDialog: React.FC<IdentityDialogProps> = (props: IdentityDialogProps) => {
    const [ newName, changeNewName ] = useState('');
    const validateName = () => {
        const exists = !!newName.trim();
        return exists && newName.length > 2;
    };
    const {
        name_prompt_button,
        name_prompt_placeholder,
        name_prompt_sub,
        name_prompt_title
    } = findTranslation(props.translation, [
        'name_prompt_button',
        'name_prompt_placeholder',
        'name_prompt_sub',
        'name_prompt_title'
    ]);

    const updateUsername = async () => {
        const [ first_name, last_name ] = newName.split(' ');

        await props.updateProfile({
            first_name,
            last_name: last_name ? last_name : '',
            prompt: Number(props.dialog.order) + 1
        });
    };

    return (
        <div className="name-dialog">
            <div className="formatted-text">
                "{name_prompt_title}"
            </div>
            <p className="formatted-text">
                {name_prompt_sub}
            </p>
            <div className="position-relative">
                <input
                    className="form-field"
                    placeholder={name_prompt_placeholder}
                    value={newName}
                    onChange={e => changeNewName(e.target.value)} />
                <div className="people-flat-icon--1" />
                <button
                    className="form-button"
                    disabled={!validateName()}
                    onClick={() => updateUsername()}>
                    {name_prompt_button}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (store: AppState) => ({
    me: store.user.me!,
    translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NicknameDialog);
