import React from 'react';
import { IUser } from '../../../../types/user';
import usersIcon from '../../../../assets/img/users.svg';
import './sport-linked.component.scss';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IModalDialog } from '../../../../store/modal/modal.reducer';
import { openModal, closeModal } from '../../../../store/modal/modal.actions';
import { friendsDialog } from '../../dialogs';
import { ISport, SportPreferences, LIKE_TO_TRY, NOT_INTERESTED } from '../../../../types/sport';
import { AppState } from '../../../../store';
import { ITranslation } from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import Confetti from 'react-confetti';

interface ISportLinkedDialogProps extends RouteComponentProps {
  linkedFriends: IUser[];
  selectedSport: ISport;
  sportPreference: SportPreferences;
  translation: ITranslation[];
  openModal: (modalProps: IModalDialog) => void;
  closeModal: () => void;
}

const SportLinkedDialog: React.FC<ISportLinkedDialogProps> = (props: ISportLinkedDialogProps) => {
  const openSportOverview = () => {
    props.closeModal();
    props.history.push('/try/sport-overview');
  }
  const { match_title } = findTranslation(props.translation, [ 'match_title' ]);
  const targetColor = props.sportPreference === LIKE_TO_TRY ? 'green' : 'blue';
  const { icon } = props.selectedSport.icons.find(icon => icon.color === targetColor)!;

  const excludeNotIterestedUsers = (friend: IUser) => {
    const sportPref = friend.sports.find(({ sport }) => sport === props.selectedSport.id);
    return sportPref!.result !== NOT_INTERESTED;
  }
  const linkedFriends = props.linkedFriends.filter(excludeNotIterestedUsers);

  return (
    <div className="sport-linked-dialog">
      <Confetti canvasRef={React.createRef()}/>
      <div className="current-sport">
        <div className="pictogram-label">{props.selectedSport.name}</div>
        <div className="sport-pictogram">
          <img src={icon} alt="sport-icon"/>
        </div>
      </div>

      <div className="title">{match_title}</div>
      <div className="friends">
        { linkedFriends.map((friend, index) => {
            // display only first 3 item
            if (index < 3) {
              const friendPref =  friend.sports.find(({ sport }) => sport === props.selectedSport.id!)!.result;
              return (
                <div className={`friend-user user ${friendPref}`} key={friend.username}>
                  <img
                    className="sport-linked__avatar"
                    src={friend.picture}
                    alt="friend-avatar"/>
                  <div className="full-name">
                    <div className="first-name">{friend.first_name}</div>
                    <div className="last-name">{friend.last_name}</div>
                  </div>
                </div>
              )
            }
          })
        }
      </div>

      { linkedFriends.length > 3 && (
          <button
            onClick={() => props.openModal(friendsDialog())}
            className="friends-page-button link"
          >
              <img src={usersIcon} alt="friends-icon"/>
              <span>{`+${linkedFriends.length - 3} more`}</span>
          </button>
      )}

      <span
        className="link"
        onClick={openSportOverview}
      >
        Your sports
      </span>
    </div>
  )
}

const maptStateToProps = (store: AppState) => ({
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openModal: (modalProps: IModalDialog) => dispatch(openModal(modalProps)),
  closeModal: () => dispatch(closeModal())
})

export default connect(
  maptStateToProps,
  mapDispatchToProps
)(withRouter(SportLinkedDialog));
