import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../../../store/modal/modal.actions';
import { AppState } from '../../../../store';
import {ITranslation, IUserPrompt} from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import './mail-sent-confirmation.component.scss';
import {IUser} from "../../../../types/user";
import {IModalDialog} from "../../../../store/modal/modal.reducer";

interface IMailSentConfirmationProps {
  translation: ITranslation[];
  email: string;
  me: IUser;
  cb?: () => void;
  onClose: () => void;
  isOnIdentity?: boolean;
  userPrompt: IUserPrompt[];
  openModal: (modalProps: IModalDialog) => void
}
const MailSentConfimation: React.FC<IMailSentConfirmationProps> = (props: IMailSentConfirmationProps) => {
  const {
    email_prompt_success_title,
    email_prompt_success_sub,
    common_ok_button
  } = findTranslation(props.translation, [
    'email_prompt_success_title',
    'email_prompt_success_sub',
    'common_ok_button'
  ]);

  const onClose = async () => {
      if (props.cb) {
          await props.cb!();
      } else {
          props.onClose();
      }
  };

  return (
    <div className="save-email-dialog">
      <div className="title">
        {email_prompt_success_title}<br/>
        {props.email}
      </div>
      <p className="dialog-description">
        {email_prompt_success_sub}
      </p>

      <button className="form-button" onClick={() => onClose()}>
        {common_ok_button}
      </button>
    </div>
  )
};

const maptStateToProps = (store: AppState) => ({
    me: store.user.me!,
    userPrompt: store.config.userPrompt,
    translation: store.config.translation
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => dispatch(closeModal()),
  openModal: (modalProps: IModalDialog) => dispatch(openModal(modalProps)),
});

export default connect(
  maptStateToProps,
  mapDispatchToProps
)(MailSentConfimation);
