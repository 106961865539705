import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { updateProfile } from '../../../../store/user/user.actions';
import { closeModal } from '../../../../store/modal/modal.actions';
import './attach-bith-year-dialog.scss';
import { isBirthYearValid } from '../../../../helpers/dateValidation';
import { AppState } from '../../../../store';
import { ITranslation } from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';

interface IAttachBirthYearDialogProps {
  translation: ITranslation[];
  updateBithYear: (birthyear: number) => void;
  onClose: () => void;
}
const AttachBirthYearDialog: React.FC<IAttachBirthYearDialogProps> = (props: IAttachBirthYearDialogProps) => {
  const [ birthyear, setBirthYear ] = useState('');

  const onUpdateBirthYear = async  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await props.updateBithYear(+birthyear);
    props.onClose();
  }

  const {
    birthday_prompt_title,
    common_ok_button
  } = findTranslation(props.translation, [
    'birthday_prompt_title',
    'common_ok_button'
  ]);

  return (
    <div className="birth-year-dialog">
      <p>
        {birthday_prompt_title}
      </p>

      <form onSubmit={onUpdateBirthYear}> 
        <input
          className="form-field"
          placeholder="YYYY"
          value={birthyear}
          type="number"
          onChange={(e) => setBirthYear(e.target.value)}/>

        <button
          className="form-button"
          disabled={!isBirthYearValid(+birthyear)}
          type="submit"
        >
          {common_ok_button}
        </button>
      </form>
    </div>
  )
};

const mapStateToProps = (store: AppState) => ({
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateBithYear: (birthyear: number) => dispatch(updateProfile({ birthyear })),
  onClose: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttachBirthYearDialog);