import { errorDialog } from "../components/Modal/dialogs";
import { openSubModal } from "../store/modal/modal.actions";
import { AxiosError } from 'axios';
import _ from "lodash";

export const handleError = (error: AxiosError, onClose?: () => void) => {
  let response = error!.response;
  const common = error.message;

  let detail = null;
  let msg = null;

  if (response && response.data) {
    detail = response.data.detail;
    msg = response.data.message;
  }
  if (msg && typeof msg === 'string') {
    return openSubModal(errorDialog(msg, false, onClose));
  }
  msg = _
    .chain(msg)
    .values()
    .first()
    .value();

  return openSubModal(errorDialog(msg || detail || common, false, onClose));
};
