// modal
export const OPEN_MODAL = 'open_modal';
export const OPEN_SUB_MODAL = 'open_sub_modal';
export const CLOSE_MODAL = 'close_modal';
export const OPEN_SUB_MODAL_INV = 'open_sub_modal_inv';
export const NEXT_MODAL = 'next_modal';
// user
export const USER_CREATED = 'user_created';
export const USER_UPDATED = 'user_updated';
export const AVATAR_UPDATED = 'avatar_updated';
export const WHOAMI = 'whoami';
export const SPORT_ADDED = 'sport_added';
// sport_moments
export const SPORT_MOMENTS_LIST = 'sport_moments_list';
export const SPORT_MOMENTS_CREATED = 'sport_moments_created';
export const SPORT_MOMENT_CREATED = 'sport_moment_created';
export const SPORT_MOMENT_DELETED = 'sport_moment_deleted';
// sport
export const SPORT_LIST_TRANSLATED = 'sport_list_translated';
export const SPORT_NEXT_TRY_SPORT = 'sport_next_try_sport';
// config
export const LIST_USER_PROMPT_ORDER = 'list_user_prompt_order';
export const TRANSLATON_FETCHED = 'translation_fetched';
export const COUNTRIES_FETCHED = 'countries_fetched';
// friends
export const FRIENDS_LIST = 'friends_list';
export const FRIEND_CREATED = 'friend_created';
