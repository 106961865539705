import React from 'react';
import { connect } from 'react-redux';
import settingsIcon from '../../assets/img/icn_menu.svg';
import { Dispatch } from 'redux';
import { openModal } from '../../store/modal/modal.actions';
import { IModalDialog } from '../../store/modal/modal.reducer';
import { menuDialog } from '../Modal/dialogs';
import { RouteComponentProps, withRouter } from 'react-router';
import iconBack from '../../assets/img/icn_back.svg';
import './header.component.scss';
import { AppState } from '../../store';
import { ITranslation } from '../../types/config';
import { findTranslation } from '../../utils/findTranslation';

interface IHeaderProps extends RouteComponentProps {
  openModal: (modalProps: IModalDialog) => void;
  isHomePage?: boolean;
  translation: ITranslation[];
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const {
    main_page_title,
    main_page_sub
  } = findTranslation(props.translation, [
    'main_page_title',
    'main_page_sub'
  ]);

  return (
    <div className="app-header">
      { !props.isHomePage && (
          <img 
            onClick={() => props.history.goBack()}
            className="nav-back"
            src={iconBack}
            alt="nav-back"/>
      )}
      <div className="page-name">
        <span className="title">{main_page_title}</span>
        <span className="description">{main_page_sub}</span>
      </div>
    </div>
  )
}

const maptStateToProps = (store: AppState) => ({
  translation: store.config.translation
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  openModal: (modalProps: IModalDialog) => dispatch(openModal(modalProps))
});

export default connect(
  maptStateToProps,
  mapDispatchToProps
)(withRouter(Header));
