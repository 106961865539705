import React from 'react';
import Header from '../../components/Header/header.component';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../store';
import { IUser } from '../../types/user';
import {ISport, ALREADY_PLAYED, LIKE_TO_TRY, ICustomLabels, IconColors, ISportCategory} from '../../types/sport';
import { fetchTranslatedSportList } from '../../store/sport/sport.actions';
import './sports-overview.component.scss';
import { fetchFriends } from '../../store/friends/friends.actions';
import { assocSportsWithUser } from '../../helpers/assocSportsWithUser';
import { ITranslation } from '../../types/config';
import { findTranslation } from '../../utils/findTranslation';
import { UndoIcon } from '../../components/Icon/undo.component';
import {IModalDialog} from "../../store/modal/modal.reducer";
import {openSubModalInv} from "../../store/modal/modal.actions";
import {friendsDialog} from "../../components/Modal/dialogs";

interface ISportOverviewProps extends RouteComponentProps {
  me: IUser;
  sportList: ISport[];
  friendList: IUser[];
  translation: ITranslation[];
  fetchFriends: () => void;
  fetchTranslatedSportList: (language: string) => void;
  openSubModalInv: (modalProps: IModalDialog) => void;
}

class SportsOverview extends React.Component<ISportOverviewProps> {
  componentDidMount() {
    if (!this.props.sportList.length) {
      this.props.fetchTranslatedSportList(this.props.me.language);
    }

    if (!this.props.friendList.length) {
      this.props.fetchFriends();
    }
  }

  public sportLine = (categoryColor: IconColors, sport: ISport) => {
    const { icon } = sport.icons.find(icon => icon.color === categoryColor)!;
    
    return (
      <div className="sport-line" key={sport.id}>
        <div className="sport-pictogram">
          <img src={icon} alt="sport-icon"/>
        </div>
  
        <span className="bold-text">{sport.name}</span>
  
        <Link className="fab-button" to={{
          pathname: '/try/home',
          state: {
            displayedSport: sport
          }
        }}>
          <div className="undo-icon">
            <UndoIcon />
          </div>
        </Link>
      </div>
    )
  };

  public commonInterest = (targetSport: ISport) => {
    const friends = this.props.friendList.filter(
      friend => friend.sports.some(
        ({ sport }) => sport === targetSport.id!));

    return friends.map(user => (
      <div className="user" key={user.username}>
        <img
          className="avatar"
          alt="user-avatar"
          src={user.picture}/>
        <div className="fullname">
          {`${user.first_name || ''} ${user.last_name || ''}`}
        </div>
      </div>
    ))
  }

  render() {
    const {
      sports_overview_like_to_try,
      sports_overview_already_played,
      sport_overview_button
    } = findTranslation(this.props.translation, [
      'sports_overview_like_to_try',
      'sports_overview_already_played',
      'sport_overview_button'
    ]);
    
    const sportPreferencesLabels: ICustomLabels[] = [
      { 
        label: sports_overview_like_to_try,
        value: LIKE_TO_TRY
      },
      {
        label: sports_overview_already_played,
        value: ALREADY_PLAYED
      }
    ];

    const categories = assocSportsWithUser(
      this.props.me,
      this.props.sportList,
      sportPreferencesLabels,
      this.props.me,
      true
    );

    return (
      <>
        <Header/>
        <div className="sports-overview">
          { categories.map(category => (
              <div key={category.label}>
                <div className="title">{category.label}</div>
                { category.sports.map((item) => (
                    <div key={item!.id}>
                      {this.sportLine(category.color, item!)}
                      {this.commonInterest(item!) }
                    </div>
                  )
                )}
              </div>
            ))
          }
          <button
            className="form-button"
            onClick={() => this.props.history.push('/try/home')}
          >
            {sport_overview_button}
          </button>
        </div>
      </>
    )
  }
}

const mapStateToProps = (store: AppState) => ({
  me: store.user.me!,
  friendList: store.friends.friendList,
  sportList: store.sport.sportList,
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTranslatedSportList: (language: string) => dispatch(fetchTranslatedSportList(language)),
  fetchFriends: () => dispatch(fetchFriends()),
  openSubModalInv: (modalProps: IModalDialog) => dispatch(openSubModalInv(modalProps))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SportsOverview))
