import axios from '../../utils/http';

export class FriendsRepo {
  public static async list() {
    return axios.get('/user/friend/')
      .then(res => res.data);
  }

  public static async fetchBySport(sportId: number) {
    return axios.get(`/user/friend/?sport=${sportId}`)
      .then(res => res.data);
  }

  public static async create(user: string, friend: string) {
    return axios.post('/friend/', { user, friend })
      .then(res => res.data);
  }
}