import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import emailValidator from 'email-validator';
import { updateProfile } from '../../../../store/user/user.actions';
import { closeModal } from '../../../../store/modal/modal.actions';
import { AppState } from '../../../../store';
import { ITranslation } from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import './attach-email-dialog.component.scss';
import {IUser} from "../../../../types/user";

interface IAttachEmailDialogProps {
  translation: ITranslation[];
  updateProfile: (profile: Partial<IUser>) => void;
  me: IUser;
  onClose: () => void;
}
const AttachEmailDialog: React.FC<IAttachEmailDialogProps> = (props: IAttachEmailDialogProps) => {
  const [email, setEmail] = useState('');

  const onUpdateEmail = () => {
    props.updateProfile({
      email,
      prompt: Number(props.me.prompt) + 1
    });
  };

  const validateEmail = () => {
    const exists = !!email.trim();
    return exists && emailValidator.validate(email);
  };

  const {
    email_prompt_2_title,
    email_prompt_2_form,
    email_prompt_2_placeholder,
    common_ok_button,
    email_prompt_2_bottom_1
  } = findTranslation(props.translation, [
    'email_prompt_2_title',
    'email_prompt_2_form',
    'email_prompt_2_placeholder',
    'common_ok_button',
    'email_prompt_2_bottom_1'
  ]);

  return (
    <div className="attach-email">
      <p className="dialog-description">
        {email_prompt_2_title}
      </p>
      <p>
        {email_prompt_2_form}
      </p>

      <div>
        <input
          id="email"
          type="email"
          placeholder={email_prompt_2_placeholder}
          className="form-field"
          onChange={(e) => setEmail(e.target.value)}
          value={email}/>
        <button
          className="form-button"
          onClick={() => onUpdateEmail()}
          disabled={!validateEmail()}
        >
          {common_ok_button}
        </button>
      </div>

      <p>
        {email_prompt_2_bottom_1}
      </p>
    </div>
  );
};

const mapStateToProps = (store: AppState) => ({
  translation: store.config.translation,
  me: store.user.me!
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile, false, { emailType: 'play' })),
  onClose: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttachEmailDialog);
