import { IUser } from '../../types/user';
import { FRIENDS_LIST, FRIEND_CREATED } from '../action-types';
import { Action } from 'redux';

export interface IFriendsState {
  friendList: IUser[];
}
export interface IFriendsAction extends Action {
  payload: {
    friendList?: IUser[];
    friend?: IUser
  };
}

const initState: IFriendsState = {
  friendList: []
};

export const friendsReducer = (state = initState, action: IFriendsAction): IFriendsState => {
  switch(action.type) {
    case FRIENDS_LIST:
      return { friendList: action.payload.friendList! };
    case FRIEND_CREATED: {
      const { friend } = action.payload;

      if (state.friendList.some(({ username }) => friend!.username === username)) {
        return state;
      }

      return {
        friendList: [
          ...state.friendList,
          friend!
        ]
      };
    }
    default:
      return state;
  }
};