import { ISportMoment } from '../../types/sport-moment';
import { Action } from 'redux';
import { SPORT_MOMENT_DELETED, SPORT_MOMENTS_LIST, SPORT_MOMENTS_CREATED } from '../action-types';

interface ISportMomentState {
  sportMoments: ISportMoment[];
}
interface ISportMomentAction extends Action {
  payload: ISportMomentState;
}

const initState: ISportMomentState = {
  sportMoments: []
}

export const sportMomentsReducer = (state = initState, action: ISportMomentAction): ISportMomentState => {
  switch(action.type) {
    case SPORT_MOMENTS_LIST:
      return { ...action.payload };
    case SPORT_MOMENTS_CREATED:
      return { ...action.payload };
    case SPORT_MOMENT_DELETED:
      return { ...action.payload };
    default: 
      return state;
  }
}