import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../../../store';
import { closeModal } from '../../../../store/modal/modal.actions';
import { updateProfile } from '../../../../store/user/user.actions';
import { IUser } from '../../../../types/user';
import './share.component.scss';
import {ITranslation, IUserPrompt} from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import donnaAvatar from "../../../../assets/img/donna.jpg";

interface IShareDialogProps {
  me: IUser;
  dialog: IUserPrompt;
  translation: ITranslation[];
  updateProfile: (profile: Partial<IUser>) => void;
  closeModal: () => void;
}
const ShareDialog: React.FC<IShareDialogProps> = (props: IShareDialogProps) => {
  const [ firstName, setFirstName ] = useState(props.me.first_name);

  const generateMessage = () => {
    const { share_social } = findTranslation(props.translation, ['share_social']);

    return share_social
        .replace(/https:\/\/[^\s|]+/, `$&?user=${props.me.username}&language=${props.me.language}`)
        .replace('<code>', props.me.username);
  };

  const onSave = async () => {
    const navigator: any = window.navigator;
    if (navigator && navigator.share) {
      const shared = {
        title: 'Share',
        text: generateMessage(),
      };
      navigator.share(shared);
    } else {
      submit();
    }
  };

  const submit = () => {
    props.updateProfile({
      prompt: Number(props.dialog.order) + 1
    });
  };

  const {
    split_share_prompt_title,
    split_share_prompt_sub,
    split_share_prompt_button_1,
    split_share_prompt_button_2,
  } = findTranslation(props.translation, [
    'split_share_prompt_title',
    'split_share_prompt_sub',
    'split_share_prompt_button_1',
    'split_share_prompt_button_2',
  ]);

  return (
    <div className="share-dialog">
      <div className="formatted-text">
        "{split_share_prompt_title}"
      </div>
      
      <p className="formatted-text">
        {split_share_prompt_sub}
      </p>

      <div>
        <button
            className="form-button form-button--rect form-button--orange"
            onClick={onSave}>
          {split_share_prompt_button_1}
        </button>
        <button
            className="form-button form-button--link"
            onClick={() => submit()}>
          {split_share_prompt_button_2}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (store: AppState) => ({
  me: store.user.me!,
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
  closeModal: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareDialog);
