import React from 'react';
import { IUser } from '../../../../types/user';
import { connect } from 'react-redux';
import { ITranslation } from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import './new-friend.component.scss';
import { AppState } from '../../../../store';
import Confetti from 'react-confetti';

interface INewFriendDialogProps {
  friend: IUser;
  translation: ITranslation[];
  friendCount: number;
}

export const NewFriendDialog: React.FC<INewFriendDialogProps> = (props: INewFriendDialogProps) => {
  const { new_friend_title } = findTranslation(
    props.translation,
    ['new_friend_title']
  );

  const gifs = [
    'https://media.giphy.com/media/UwCxlqvH5dLJm/giphy.gif',
    'https://media.giphy.com/media/OA4LDHPBjwPGU/giphy.gif',
    'https://media.giphy.com/media/fIrbAgjX4Gjqo/giphy.gif',
    'https://media.giphy.com/media/HTo80ZVYgNAA/giphy.gif',
    'https://media.giphy.com/media/4mKnIE5LBc2I/giphy.gif',
    'https://media.giphy.com/media/EvYHHSntaIl5m/giphy.gif',
    'https://media.giphy.com/media/Hld9vKjLk3irC/giphy.gif',
    'https://media.giphy.com/media/mcJgrHDG3nXLW/giphy.gif',
    'https://media.giphy.com/media/5wWf7GW1AzV6pF3MaVW/giphy.gif',
    'https://media.giphy.com/media/WrGiAHYhZZYZ2/giphy.gif'
  ];

  const gifIndex = props.friendCount > gifs.length - 1 ?
    props.friendCount % gifs.length : props.friendCount;

  return (
    <div className="new-friend-dialog">
      <Confetti canvasRef={React.createRef()}/>
      <div className="title">{new_friend_title}</div>
      <div className="user">
        <img
          className="avatar"
          src={props.friend.picture}
          alt="friend-avatar"/>
        <div className="full-name">
          <div className="first-name">{props.friend.first_name}</div>
          <div className="last-name">{props.friend.last_name}</div>
        </div>
      </div>

      <img className="gif-viewer" alt="gif-congratulation" src={gifs[gifIndex]}/>
    </div>
  )
};

const maptStateToProps = (store: AppState) => ({
  translation: store.config.translation,
  friendCount: store.friends.friendList.length
});

export default connect(
  maptStateToProps
)(NewFriendDialog);
