import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { ITranslation } from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import './sport-moments-saved-confirmation.components.scss';
import { IUser } from '../../../../types/user';
import { updateProfile } from '../../../../store/user/user.actions';

interface ISportMomentsSavedConfirmationProps {
  translation: ITranslation[];
  updateProfile: (profile: Partial<IUser>) => void;
  me: IUser;
}
const SportMomentsSavedConfirmation: React.FC<ISportMomentsSavedConfirmationProps> = (props: ISportMomentsSavedConfirmationProps) => {
  const {
    moments_success_title,
    location_success_sub
  } = findTranslation(props.translation, [
    'moments_success_title',
    'location_success_sub'
  ]);

  useEffect(() => {
    setTimeout(() => {
      props.updateProfile({
        prompt: 55
      });
    }, 3000)
  })

  return (
    <div className="sport-moments-confirmation">
      <div className="title">
        {moments_success_title}
      </div>
      <p className="dialog-description">
        {location_success_sub}
      </p>
    </div>
  )
};

const maptStateToProps = (store: AppState) => ({
  me: store.user.me!,
  translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile))
});

export default connect(
  maptStateToProps,
  mapDispatchToProps
)(SportMomentsSavedConfirmation);