import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import {closeModal, openSubModal} from '../../../../store/modal/modal.actions';
import './email-dialog.component.scss';
import { ITranslation, IUserPrompt } from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import { updateProfile } from '../../../../store/user/user.actions';
import { IUser } from '../../../../types/user';
import emailValidator from "email-validator";
import { identityDialog } from "../../dialogs";
import { IModalDialog } from "../../../../store/modal/modal.reducer";

interface IdentityDialogProps {
  userPrompt: IUserPrompt[];
  translation: ITranslation[];
  closeModal: () => void;
  openSubModal: (modalDialog: IModalDialog) => void;
  updateProfile: (profile: Partial<IUser>) => void;
  dialog: IUserPrompt;
  me: IUser,
}

const NicknameDialog: React.FC<IdentityDialogProps> = (props: IdentityDialogProps) => {
    const [ email, changeEmail ] = useState('');
    const {
        email_onboarding_button,
        email_onboarding_placeholder,
        email_onboarding_sub,
        email_onboarding_title
    } = findTranslation(props.translation, [
        'email_onboarding_button',
        'email_onboarding_placeholder',
        'email_onboarding_sub',
        'email_onboarding_title'
    ]);

    const onUpdateEmail = () => {
        props.updateProfile({
            email,
            prompt: Number(props.me.prompt) + 1
        });
    };

    const validateEmail = () => {
        const exists = !!email.trim();
        return exists && emailValidator.validate(email);
    };

    const openIdentityPrompt = () => {
        const dialog = props.userPrompt.find(item => item.prompt === 'identity_prompt')
        props.openSubModal(identityDialog(dialog!));
    }

    return (
        <div className="name-dialog">
            <div className="formatted-text">
                "{email_onboarding_title}"
            </div>
            <p className="formatted-text">
                {email_onboarding_sub}
            </p>
            <div className="position-relative">
                <input
                    id="email"
                    type="email"
                    className="form-field"
                    placeholder={ email_onboarding_placeholder }
                    value={email}
                    onChange={e => changeEmail(e.target.value)} />
                <button
                    className="form-button"
                    disabled={!validateEmail()}
                    onClick={() => onUpdateEmail()}>
                    { email_onboarding_button }
                </button>
                <div className="people-flat-icon--9" />
                <div onClick={() => openIdentityPrompt()} className="form-button--link">
                   Login
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (store: AppState) => ({
    me: store.user.me!,
    translation: store.config.translation,
    userPrompt: store.config.userPrompt
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    openSubModal: (modalProps: IModalDialog) => dispatch(openSubModal(modalProps)),
    updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile, false, { emailType: 'app' })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NicknameDialog);
