import { Dispatch } from 'redux';
import { FriendsRepo } from './friends.repository';
import { FRIENDS_LIST, FRIEND_CREATED } from '../action-types';
import { buildNewFriendDialog } from '../../components/Modal/dialogs';
import { handleError } from '../../utils/handleErrors';
import { store } from '..';
import { UserRepo } from '../user/user.repository';
import { openSubModalInv } from '../modal/modal.actions';

export const fetchFriends = (): any => (
  async (dispatch: Dispatch) => {
    try {
      const friendList = await FriendsRepo.list();
      dispatch({
        type: FRIENDS_LIST,
        payload: { friendList }
      });
    } catch (error) {
      dispatch(handleError(error))
    }
  }
);

export const addFriend = (friendCode: string, inSequence: boolean = false): any => (
  async (dispatch: Dispatch) => {
    try {
      const me = store.getState().user.me!;
      const { friend } = await FriendsRepo.create(me.username, friendCode);
      const newFriend = await UserRepo.retreive(friend.username);

      dispatch({
        type: FRIEND_CREATED,
        payload: { friend: newFriend }
      });

      if (Number(me.prompt) > 0 && (newFriend.first_name && !newFriend.source_account)) {
          const newFriendDialog = buildNewFriendDialog(newFriend, inSequence);
          dispatch(openSubModalInv(newFriendDialog));
      }

      return newFriend;
    } catch (error) {
      dispatch(handleError(error));
    }
  }
);
