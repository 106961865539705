import React from 'react';
import ReactDOM from 'react-dom';
import { IPicture } from '../../types/sport';
import Swiper, { SelectableElement } from 'swiper';
import './image-viewer.component.scss'

interface IImageViewerProps {
  name: string;
  images: IPicture[];
}

export class ImageViewer extends React.Component<IImageViewerProps> {
  public state = {
    selectedImageIndex: 0
  }
  public swiper: any;

  componentDidMount() {
    this.swiper = new Swiper(ReactDOM.findDOMNode(this)! as SelectableElement, {
       speed: 400,
       pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return `<span class="control ${className}"></span>`;
        },
      },
    });
  }

  componentDidUpdate() {
    this.swiper.slideTo(0);
    this.swiper.update();
  }
  
  render() {
    return (
      <div className="image-viewer swiper-container">
        <div className="sport-title">
          <div>
            {this.props.name}
          </div>
        </div>
        <div className="swiper-wrapper">
          {
              this.props.images ?
                  this.props.images.map(({ picture, id }, index) => (
                      <div
                        key={index}
                        className="swiper-slide"
                        style={{ backgroundImage: 'url(' + picture + ')' }}
                      />
                    )) : []
          }
        </div>
        <div className="swiper-pagination"></div>
      </div>
    )
  }
}
