import { IModalAction, IModalDialog } from './modal.reducer';
import { OPEN_MODAL, CLOSE_MODAL, OPEN_SUB_MODAL, OPEN_SUB_MODAL_INV } from './../action-types';
import { store } from '../index';

export const openModal = (payload: IModalDialog): IModalAction => {
  return { type: OPEN_MODAL, payload };
};

export const openSubModal = (payload: IModalDialog): IModalAction => {
  return { type: OPEN_SUB_MODAL, payload };
};

export const openSubModalInv = (payload: IModalDialog): IModalAction => {
  return { type: OPEN_SUB_MODAL_INV, payload };
};

export const closeModal = (): IModalAction => {
  const me = store.getState().user.me;
  return { type: CLOSE_MODAL, payload: {user: me}
  };
};
