import React, {useState} from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { closeModal } from '../../../../store/modal/modal.actions';
import './weekly-habit-dialog.component.scss';
import {ITranslation, IUserPrompt} from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import { updateProfile } from '../../../../store/user/user.actions';
import { IUser } from '../../../../types/user';

interface WeeklyHabitDialogProps {
    translation: ITranslation[];
    closeModal: () => void;
    updateProfile: (profile: Partial<IUser>) => void;
    dialog: IUserPrompt;
    me: IUser,
}

const WeeklyHabitDialog: React.FC<WeeklyHabitDialogProps> = (props: WeeklyHabitDialogProps) => {
    const [ frequency, changeFrequency ] = useState<string>("");
    const {
        weekly_habit_prompt_title,
        weekly_habit_prompt_button,
        weekly_habit_prompt_sub
    } = findTranslation(props.translation, [
        'weekly_habit_prompt_title',
        'weekly_habit_prompt_button',
        'weekly_habit_prompt_sub'
    ]);

    const options = new Array(8)
        .fill(null)
        .map((item, key) => {
            const option = {
                value: key < 7 ? key : 8,
                text: key < 7 ? key : '> 7'
            };
            return <option value={ option.value } key={ option.value }>{ option.text }</option>
        });


    const updateUsername = async () => {
        await props.updateProfile({
            weekly_habit: Number(frequency),
            prompt: Number(props.dialog.order) + 1
        });
    };

    return (
        <div className="weekly-habit-dialog">
            <div className="formatted-text">
                "{weekly_habit_prompt_title}"
            </div>
            <p className="formatted-text">
                {weekly_habit_prompt_sub}
            </p>
            <div className="position-relative">
                <select className="form-field"
                    defaultValue={frequency}
                    onChange={e => changeFrequency(e.target.value)}>
                    <option value="" disabled>
                       ---
                    </option>
                    { options }
                </select>
                <div className="select__arrow" />
                <div className="people-flat-icon--1" />
                <button
                    className="form-button"
                    disabled={!frequency}
                    onClick={() => updateUsername()}>
                    { weekly_habit_prompt_button }
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (store: AppState) => ({
    me: store.user.me!,
    translation: store.config.translation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WeeklyHabitDialog);
