import React, {useState} from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { closeModal } from '../../../../store/modal/modal.actions';
import './welcome-dialog.component.scss';
import {ITranslation, IUserPrompt} from '../../../../types/config';
import { findTranslation } from '../../../../utils/findTranslation';
import { updateProfile } from '../../../../store/user/user.actions';
import { IUser } from '../../../../types/user';
import { fetchTranslation } from '../../../../store/config/config.actions';

import gbLang from '../../../../assets/img/icn_flag_gb.svg';
import nlLang from '../../../../assets/img/icn_flag_nl.svg';

interface IdentityDialogProps {
    translation: ITranslation[];
    closeModal: () => void;
    updateLanguage: (language: string, leaveModal: boolean) => void;
    updateProfile: (profile: Partial<IUser>) => void;
    fetchTranslation: (language: string) => void
    me: IUser,
    dialog: IUserPrompt;
}

const WelcomeDialog: React.FC<IdentityDialogProps> = (props: IdentityDialogProps) => {
    const [ processing, changeProcessing ] = useState(false);
    const {
        welcome_says,
        welcome_button,
        welcome_sub,
        welcome_title
    } = findTranslation(props.translation, [
        'welcome_says',
        'welcome_button',
        'welcome_sub',
        'welcome_title'
    ]);

    const getName = () => {
        let name = ''
        const { first_name, last_name } = props.me.invited_by!

        if (first_name) {
            name += first_name + ' '
        }
        if (last_name) {
            name += last_name
        }

        return name
    }

    const friend = props.me.invited_by !== null ? getName() : 'Donna';
    const langImg = props.me.language !== 'en' ? nlLang : gbLang;

    const translate = async () => {
        changeProcessing(true);
        const language = props.me.language  === 'en' ? 'nl' : 'en';
        await Promise.all([
            props.updateLanguage(language, true),
            props.fetchTranslation(language)
        ]);
        changeProcessing(false);
    };

    const submit = async () => {
        await props.updateProfile({
            prompt: Number(props.dialog.order) + 1,
        });
    };

    return (
        <div className="welcome-dialog">
            <div className="img--hand" />
            <p className="text-italic">
                {friend || 'Donna'} {welcome_says}
            </p>
            <p> "{welcome_title}" </p>
            <p> {welcome_sub} </p>
            <button
                className="form-button form-button--orange-fill"
                onClick={() => submit()}>
                {welcome_button}
            </button>
            <div className="text-sz-14">
                <span className="text-italic">Change language:</span>
                <div className="img--language"
                     onClick={() => translate()}
                     style={{ backgroundImage: `url(${langImg})` }}>
                    <span className="text--lang">{ props.me.language.toUpperCase() }</span>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (store: AppState) => ({
    me: store.user.me!,
    translation: store.config.translation,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
    updateLanguage: (language: string, leaveModal: boolean) => dispatch(updateProfile({ language }, leaveModal)),
    fetchTranslation: (language: string) => dispatch(fetchTranslation(language))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WelcomeDialog);
