import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import loginIcon from '../../../assets/img/icn_login.svg';
import sportIcon from '../../../assets/img/icn_sports.svg';
import friendsIcon from '../../../assets/img/icn_friends.svg';
import gotoappIcon from '../../../assets/img/icn_gotoapp_nocircle.png';
import ProfileIcon from '../../../assets/img/icn_profile.svg';
import { IModalDialog } from '../../../store/modal/modal.reducer';
import { openModal, closeModal } from '../../../store/modal/modal.actions';
import { profileDialog, goToAppDialog, signInDialog, friendsDialog } from '../dialogs';
import './menu.component.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppState } from '../../../store';
import { ITranslation } from '../../../types/config';
import { findTranslation } from '../../../utils/findTranslation';

interface IMenuProps extends RouteComponentProps {
  translation: ITranslation[];
  openModal: (modalProps: IModalDialog) => void;
  closeModal: () => void;
}

const Menu: React.FC<IMenuProps> = (props: IMenuProps) => { 
  const {
    menu_title,
    menu_login,
    menu_sports,
    menu_friends,
    menu_gotoapp,
    menu_profile
  } = findTranslation(props.translation, [
    'menu_title',
    'menu_login',
    'menu_sports',
    'menu_friends',
    'menu_gotoapp',
    'menu_profile'
  ]);

  const menuOptions = [
    /*{
      name: menu_login,
      icon: loginIcon,
      action: () => props.openModal(signInDialog)
    },*/
    {
      name: menu_sports,
      icon: sportIcon,
      action: () => {
        props.history.push('/try/sport-overview');
        props.closeModal();
      }
    },
    {
      name: menu_friends,
      icon: friendsIcon,
      action: () => props.openModal(friendsDialog())
    },
    {
      name: menu_gotoapp || 'Go to app',
      icon: gotoappIcon,
      iconClass: 'go-to-app-icon',
      action: () => props.openModal(goToAppDialog(null, false))
    },
    {
      name: menu_profile,
      icon: ProfileIcon,
      action: () => props.openModal(profileDialog)
    },
  ];

  return (
    <div className="menu">
      <p className="title">
        {menu_title}
      </p>
      {
        menuOptions.map((option, index) => (
          <div key={index} onClick={option.action}>
            <div className="menu-option">
              <div className="pictogram">
                <img src={option.icon} alt={option.name} className={option.iconClass}/>
              </div>
              <div className="name title">
                <span>{option.name}</span>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
};

const mapStateToProps = (store: AppState) => ({
  translation: store.config.translation
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  openModal: (modalProps: IModalDialog) => dispatch(openModal(modalProps)),
  closeModal: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Menu));
