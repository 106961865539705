import { Dispatch } from 'redux';
import { ConfigRepo } from './config.repository';
import { LIST_USER_PROMPT_ORDER, TRANSLATON_FETCHED, COUNTRIES_FETCHED } from '../action-types';
import { handleError } from '../../utils/handleErrors';

export const listUserPrompOrder = (language?: string): any => (
  async (dispatch: Dispatch) => {
    try {
      const userPrompt = await ConfigRepo.listUserPromptOrder(language);
      dispatch({
        type: LIST_USER_PROMPT_ORDER,
        payload: { userPrompt }
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  }
)

export const fetchTranslation = (language?: string): any => (
  async (dispatch: Dispatch) => {
    try {
      const translation = await ConfigRepo.translation(language);
      dispatch({
        type: TRANSLATON_FETCHED,
        payload: { translation }
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  }
)

export const fetchCountries = (): any => (
  async (dispatch: Dispatch) => {
    try {
      const countries = await ConfigRepo.fetchCountries();
      dispatch({
        type: COUNTRIES_FETCHED,
        payload: { countries }
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  }
)
