import React, { createRef, RefObject, forwardRef, Ref, ForwardRefExoticComponent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { openModal, closeModal, openSubModal } from '../../../store/modal/modal.actions';
import { IModalDialog } from '../../../store/modal/modal.reducer';
import { AppState } from '../../../store';
import { IUser, genderStates, Gender } from '../../../types/user';
import iconCamera from '../../../assets/img/icn_camera.svg';
import { isBirthYearValid } from '../../../helpers/dateValidation';
import { updateProfile, updateAvatar } from '../../../store/user/user.actions';
import { IDate, DATE_FORMAT } from '../../../types/date';
import SportMoments from '../../SportMoments/sport-moments.component';
import './profile.component.scss';
import { attachEmailDialog, attachLocationDialog, genderSelectionDialog } from '../dialogs';
import { findTranslation } from '../../../utils/findTranslation';
import { ITranslation, ICountries } from '../../../types/config';
import { FormDropdown, ISelectOption } from '../../FormDropdown/form-dropdown.component';
import { ValueType } from 'react-select/src/types';
import { fetchCountries } from '../../../store/config/config.actions';

type DateProperty = 'date' | 'month' | 'year';

interface IPofileState {
  profile: Partial<IUser>;
  dateBuilder: IDate;
  fullNameBuilder: string;
  previewAvatar: string;
}
interface IProfileProps {
  me: IUser;
  closeModal: () => void;
  openModal: (modalProps: IModalDialog) => void;
  openSubModal: (modalProps: IModalDialog) => void;
  updateProfile: (profile: Partial<IUser>) => void;
  updateAvatar: (avatar: FormData) => void;
  fetchCountries: () => void;
  translation: ITranslation[];
  countries: ICountries;
}

class Profile extends React.Component<IProfileProps, IPofileState> {
  public selectMomentRef: RefObject<any>;
  public SportMomentsWithRef: ForwardRefExoticComponent<any>;
  public avatarFile?: File;

  componentDidMount() {
    if (!this.props.countries.length) {
      this.props.fetchCountries();
    }
  }

  constructor(props: IProfileProps) {
    super(props);
    this.selectMomentRef = createRef();
    this.SportMomentsWithRef = forwardRef(
      (props: any, ref: Ref<any>) => <SportMoments ref={ref}/>
    );
    
    const { birthday, birthyear, first_name, last_name } = this.props.me;
    let fullNameBuilder = '';

    if (first_name && last_name) {
      fullNameBuilder = `${first_name || ''} ${last_name || ''}`;
    } else if (first_name) {
      fullNameBuilder = first_name;
    }

    let dateBuilder = {
      date: '',
      month: '',
      year: birthyear ? birthyear.toString() : ''
    };
    if (birthday) {
      const updatedDate = moment(birthday, DATE_FORMAT);
      dateBuilder = {
        date: (updatedDate.date()).toString(),
        month: (updatedDate.month() + 1).toString(),
        year: (updatedDate.year()).toString()
      };
    }

    const country = this.props.me.country || 'NL';
    const profile = {
      ...this.props.me,
      ...{ country: country }
    };

    this.state = {
      fullNameBuilder,
      profile,
      previewAvatar: this.props.me.picture,
      dateBuilder
    }
  }

  public onChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    this.setState({
      profile: {
        ...this.state.profile,
        [key]: e.currentTarget.value 
      }
    });
  };

  public onGenderChanged = (option: ValueType<ISelectOption>) => {
    const value = (option as ISelectOption).value;
    this.setState({
      profile: {
        ...this.state.profile,
        gender: value as Gender
      }
    })
  };

  public onCountryChanged = (option: ValueType<ISelectOption>) => {
    const value = (option as ISelectOption).value;
    this.setState({
      profile: {
        ...this.state.profile,
        country: value
      }
    })
  };

  public onFullNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.includes(' ')) {
      return this.setState({
        fullNameBuilder: e.target.value,
        profile: {
          ...this.state.profile,
          first_name: e.target.value,
        }
      });
    }
    const [ first_name, last_name ] = e.target.value.split(' ');
    this.setState({
      fullNameBuilder: e.target.value,
      profile: {
        ...this.state.profile,
        first_name,
        last_name,
      }
    });
  };

  public changeAvatar = (files: React.ChangeEvent<HTMLInputElement>) => {
    // TODO validate size
    this.avatarFile = files.target.files![0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        previewAvatar: reader.result! as string
      })
    };
    reader.readAsDataURL(this.avatarFile);
  };

  public debouncedValidator = _.debounce((dateProperty: DateProperty) =>
    this.validateDate(dateProperty), 750);
  
  public onBirthDateChanged(e: React.ChangeEvent<HTMLInputElement>, dateProperty: DateProperty) {
    this.debouncedValidator(dateProperty);

    let targetValue = +e.target.value;
    if ((dateProperty !== 'year' && e.target.value.length > 2) ||
      (dateProperty === 'year' && (e.target.value.length > 4))) {
        return;
    }

    this.setState({
      dateBuilder: {
        ...this.state.dateBuilder,
        [dateProperty]: targetValue
      }
    });
  }

  public validateDate(dateProperty: DateProperty) {
    const { birthday } = this.state.profile;
    let targetValue = +this.state.dateBuilder[dateProperty];

    if (dateProperty === 'month') {
      targetValue--;
    }

    if (!birthday) {
      const birhDate = moment();
      birhDate.year(this.state.profile.birthyear!);
      birhDate[dateProperty](targetValue);
      
      const updatedDate = birhDate.format(DATE_FORMAT);
      const dateBuilder = {
        date: (birhDate.date()).toString(),
        month: (birhDate.month() + 1).toString(),
        year: (birhDate.year()).toString()
      };
      this.setState({ 
        profile: {
          ...this.state.profile,
          birthday: updatedDate
        },
        dateBuilder
      });
      return;
    }

    const birhDate = moment(birthday, DATE_FORMAT);
    const fullDate = {
      date: birhDate.date(),
      month: birhDate.month(),
      year: birhDate.year()
    };

    fullDate[dateProperty] = targetValue;
    let updatedDate = moment(`${fullDate.year}.${fullDate.month + 1}.${fullDate.date}`, DATE_FORMAT);
    
    if (!updatedDate.isValid() || (dateProperty === 'year' && !isBirthYearValid(targetValue))) {
      return this.setState({
        dateBuilder: {
          ...this.state.dateBuilder,
          [dateProperty]: '',
        }
      })
    }

    this.setState({
      profile: {
        ...this.state.profile,
        birthday: updatedDate.format(DATE_FORMAT)
      },
    });
  }
  
  public debouncedResetValue = _.debounce((dateProperty: DateProperty) =>
    this.setPreviousValue(dateProperty), 500);
  
  public setPreviousValue( dateProperty: DateProperty) {
    const { birthday } = this.state.profile;

    let targetValue = moment(birthday, DATE_FORMAT)[dateProperty]();
    if(dateProperty === 'month') {
      targetValue++;
    }

    this.setState({
      dateBuilder: {
        ...this.state.dateBuilder,
        [dateProperty]: targetValue,
      }
    })
  }

  public updateAvatar() {
    const avatarPayload = new FormData();
    avatarPayload.append('email', this.state.profile.email!);
    avatarPayload.append('picture', this.avatarFile!);
    this.props.updateAvatar(avatarPayload);
  }

  public updateProfile = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const changes = _.pickBy(this.state.profile, _.identity) as Partial<IUser>;
    const updatedProfile: {
      [key: string]: number | string | []
    } = {};
    
    _.each(changes, (value, key) => {
      if (value !== this.props.me[key]) {
        updatedProfile[key] = value!.toString();
      }
    });

    if (!_.isEmpty(updatedProfile)) {
      this.props.updateProfile(updatedProfile);
    }

    if (this.avatarFile) {
      this.updateAvatar();
    }

    await this.selectMomentRef.current.onSaveChanges();
    this.props.closeModal();
  };

  public getExtension () {
    const ext = this.state.previewAvatar.split('.').pop();
    const validator = /gif|jpe?g|tiff|png|svg/;
    const video = this.avatarFile && this.avatarFile.type.includes('video');

    if (video) {
      return 'video';
    } else {
      return validator.test(ext as string) ? 'image' : 'video';
    }
  }

  render() {
    const { email, company, department, location, work_location, role, gender, postal_code, country } = this.state.profile;

    const genderOptions: ISelectOption[] = [];
    _.each(genderStates, (value, key) => {
      genderOptions.push({
        label: _.startCase(value),
        value
      })
    });

    const countryOptions: ISelectOption[] = [];
    _.each(this.props.countries, (value, key) => {
      countryOptions.push({
        label: _.startCase(value),
        value: key
      });
    });

    const { date, month, year } = this.state.dateBuilder;
    const dateBuilder = {
      date: +date ? date : '',
      month: +month ? month : '',
      year: +year ? year : ''
    };

    const {
      profile_name,
      profile_email,
      profile_postal_code,
      profile_birth_date,
      profile_gender,
      profile_time,
      profile_work_title,
      profile_company,
      profile_department,
      profile_role,
      profile_work_location
    } = findTranslation(this.props.translation, [
      'profile_name',
      'profile_email',
      'profile_postal_code',
      'profile_birth_date',
      'profile_gender',
      'profile_time',
      'profile_work_title',
      'profile_company',
      'profile_department',
      'profile_role',
      'profile_work_location'
    ]);

    return (
      <form className="profile" onSubmit={this.updateProfile}>
        <div className="personal-info">
          
          <div className="coloumn">
            <div className="avatar-field">
              <div className="avatar">
                { this.getExtension() === 'image' &&
                  <div className="avatar-img" style={{ backgroundImage: `url(${this.state.previewAvatar})` }}/> }
                { this.getExtension() === 'video' &&
                  <video className="avatar-img" src={this.state.previewAvatar} autoPlay loop /> }
              </div>
              <label htmlFor="avatar">
                <div className="icon">
                  <img src={iconCamera} alt="camera-icon"/>
                </div>
              </label>
              <input
                className="upload-avatar"
                onChange={this.changeAvatar}
                type="file"
                id="avatar"/>
            </div>

            <div className="profile-field">
              <label className="label">{profile_name}</label>
              <input
                className="form-field"
                placeholder="Type your name"
                onChange={this.onFullNameChanged}
                value={this.state.fullNameBuilder}/>
            </div>
          </div>

          <div className="coloumn">
            <div
              className="profile-field"
              onClick={() => this.props.openSubModal(attachEmailDialog(false))}
            >
              <label className="label">{profile_email}</label>
              <input
                id="email"
                className="form-field"
                placeholder="Type your email"
                type="email"
                defaultValue={email}/>
            </div>
            {/* <div
              className="profile-field"
              onClick={() => this.props.openModal(attachLoactionDialog)}
            >
              <label className="label">Your address:</label>
              <input 
                className="form-field"
                placeholder="Type your address"
                defaultValue={location}
              />
            </div> */}
            <div className="profile-field">
              <label className="label">{profile_postal_code}</label>
              <input
                className="form-field"
                placeholder="Type your postal code"
                onChange={(e) => this.onChange(e, 'postal_code')}
                value={postal_code || ''}
              />
            </div>
            <div className="profile-field">
              <label className="label">COUNTRY</label>
              <FormDropdown 
                placeholder="Select your country"
                value={countryOptions.find(({ value }) => value === country)}
                onChange={this.onCountryChanged}
                options={countryOptions}/>
            </div>

            <div className="birth-date">
              <label className="label">{profile_birth_date}</label>
              <div>
                <input
                  className="form-field"
                  placeholder="Day" 
                  onChange={(e) => this.onBirthDateChanged(e, 'date')}
                  onBlur={() => this.debouncedResetValue('date')}
                  value={dateBuilder.date}
                />
                <input 
                  className="form-field"
                  placeholder="Month" 
                  onChange={(e) => this.onBirthDateChanged(e, 'month')}
                  onBlur={() => this.debouncedResetValue('month')}
                  value={dateBuilder.month}
                />
                <input 
                  className="form-field"
                  placeholder="Year"
                  onChange={(e) => this.onBirthDateChanged(e, 'year')}
                  onBlur={() => this.debouncedResetValue('year')}
                  value={dateBuilder.year}
                />
              </div>
            </div>
            <div className="profile-field">
              <label className="label">{profile_gender}</label>
              <FormDropdown 
                placeholder="Select your gender"
                value={genderOptions.find(({ value }) => value === gender)}
                onChange={this.onGenderChanged}
                options={genderOptions}/>
            </div>
          </div>
  
          <div className="coloumn">
            <div className="profile-field">
              <label className="label">{profile_time}</label>
              <div className="time-select">
                <this.SportMomentsWithRef ref={this.selectMomentRef}/>
              </div>
            </div>
          </div>
        </div>
  
        <div className="title">
          {profile_work_title}
          <hr/>
        </div>
  
        <div className="company-info">
          <div className="profile-field">
            <label className="label">{profile_company}</label>
            <input 
              className="form-field"
              placeholder="Type company name"
              onChange={(e) => this.onChange(e, 'company')}
              value={company || ''}/>
          </div>
          
          <div className="profile-field">
            <label className="label">{profile_department}</label>
            <input 
              className="form-field"
              placeholder="Type your department"
              onChange={(e) => this.onChange(e, 'department')}
              value={department || ''}/>
          </div>
  
          <div className="profile-field">
            <label className="label">{profile_role}</label>
            <input
              className="form-field"
              placeholder="Type your role" 
              onChange={(e) => this.onChange(e, 'role')}
              value={role || ''}/>
          </div>
  
          <div className="profile-field">
            <label className="label">{profile_work_location}</label>
            <input
              className="form-field"
              placeholder="Type your department"
              onChange={(e) => this.onChange(e, 'work_location')}
              defaultValue={work_location}
            />
          </div>
        </div>
  
        <button type="submit" className="form-button">Save changes</button>
      </form>
    )
  }
}

const mapStateToProps = (store: AppState) => ({
  me: store.user.me!,
  translation: store.config.translation,
  countries: store.config.countries
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openModal: (modalProps: IModalDialog) => dispatch(openModal(modalProps)),
  openSubModal: (modalProps: IModalDialog) => dispatch(openSubModal(modalProps)),
  updateProfile: (profile: Partial<IUser>) => dispatch(updateProfile(profile)),
  updateAvatar: (avatar: FormData) => dispatch(updateAvatar(avatar)),
  fetchCountries: () => dispatch(fetchCountries()),
  closeModal: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
