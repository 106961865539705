import { ISportMoment } from "../../types/sport-moment";
import axios from '../../utils/http';
import { IUser } from "../../types/user";

export class SportMomentRepo {
  public static async list() {
    return axios.get('/sportmoment/')
      .then(res => res.data);
  }
  public static burstCreate(sportMoments: ISportMoment[], user: IUser) {
    return axios.post(`/sportmoment/burst/${user.username}/`, sportMoments)
      .then(res => res.data);
  }
  public static create(sportMoments: ISportMoment[], user: IUser) {
    return axios.post('/sportmoment/', { ...sportMoments, user: user.username })
      .then(res => res.data);
  }
  public static delete(sportMomentId: number) {
    return axios.delete(`/sportmoment/${sportMomentId}/`);
  }
}